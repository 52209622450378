import React from "react";
import Loader from 'react-loader-spinner';
import "./LoaderSpinner.css";

export default ({
  className = "",
  loadingText = "Loading ...",
  ...props
}) =>
    <div className={`LoaderSpinner ${className}`}>
      <div className={`LoaderSpinner loading-div ${className}`}>
        <p className={`LoaderSpinner loading-text ${className}`}>{loadingText}</p>
        <Loader type="ThreeDots" color="#8fbd4a" height='26px' width='100%' {...props}/>
      </div>
    </div>;