import React, { Component } from "react";
import sizeMe from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import { Image, Row, Col, Container, Modal, Button } from "react-bootstrap";
import { Redirect} from "react-router-dom";
import LoaderSpinner from "../components/LoaderSpinner";
import * as PluginHelper from "../PluginHelper";
import log from "../logger"
import { withAlert } from 'react-alert'
import { connect } from 'react-redux'
import { formatAPIAlert } from '../components/AlertTemplate'
import "./AddNew.css";

const TAG = "AddNew"

class AddNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      modalShowInstall: false,
      pluginToInstall: {},
      pluginToInstallNow: {},
      plugins: []
    };
  }

  async componentDidMount() {
    try {
      log.info(TAG, "componentDidMount");
      const plugins = await PluginHelper.loadPluginsAddNew(this.props.account.token);
      this.setState({ plugins });
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
    }
    this.setState({ isLoading: false });
  }

  // Display install prompt
  installPrompt(plugin) {
    if (plugin) {
        if (plugin.overlayIcon) {
            // No prompt
            this.installPlugin(plugin);
        }
        else {
            // Prompt for public plugin
            this.setState({ modalShowInstall: true, pluginToInstall: plugin, pluginToInstallNow: {}});
        }
    }
  }

  installPlugin(plugin) {
    this.setState({ modalShowInstall: false, pluginToInstall: {}, pluginToInstallNow: plugin});
  }

  modalCloseInstall() {
    this.setState({ modalShowInstall: false, prepareDownload: false, pluginToInstall: {}, pluginToInstallNow: {}});
  }

  renderPluginsList(plugins) {
    const {
      size: {
        width
      }
    } = this.props;

    if (plugins && plugins.length > 0) {
        return (
          <StackGrid columnWidth={width <= 640 ? width <= 480 ? width <= 320 ? '100%' : '50%' : '33.3%' : '25%'}>
          {plugins.map((plugin) => (
            this.renderItem(plugin)
           ))}
          </StackGrid>
        );
    }
    else {
        return (
         <div>No plugin available</div>
        )
    }
  }

  renderOverlayIcon(plugin) {
    if (plugin.overlayIcon) {
      return (
        <Image className="install-icon" src={plugin.overlayIcon}/>
      )
    }
  }

  renderAdminIcon(plugin) {
      if (plugin.adminIcon) {
        return (
          <Image className="admin-icon" src={plugin.adminIcon}/>
        )
      }
  }

  renderItem(plugin) {
    return (
        <Container fluid style={{height: '116px', overflow: 'hidden'}} key={plugin.uid}>
          <Row>
            <Col>
              <a onClick={() => this.installPrompt(plugin)} style={{cursor: 'pointer'}}>
                <div className="icon-top-container">
                  <div className="icon-container">
                    {this.renderAdminIcon(plugin)}
                    {this.renderOverlayIcon(plugin)}
                    <Image className="center-image" src={plugin.iconUrl} width='72' height="72"/>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
          <Row style={{fontSize: '90%', fontWeight: 'bold'}}>
            <Col className="text-center">{plugin.name}</Col>
          </Row>
        </Container>
    );
  }

  renderPlugins() {
    const mailtoLink = 'mailto:?subject=' + PluginHelper.RECOMMEND_EMAIL_SUBJECT + '&body=' + PluginHelper.RECOMMEND_EMAIL_CONTENT;
    return (
      <div className="plugins">
        <p className="title">Select Plugin to add</p>
        <p className="recommend-title"><a href={mailtoLink} target="_blank" rel="noopener noreferrer">Recommend a company to RepFiles</a></p>

        <Modal show={this.state.modalShowInstall} onHide={() => this.modalCloseInstall()} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Add Plugin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>The {this.state.pluginToInstall.name} plugin is accessible to all registered users. Access content by adding the plugin to your home screen.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.modalCloseInstall()}>Cancel</Button>
            <Button variant="success" onClick={() => this.installPlugin(this.state.pluginToInstall)}>Add</Button>
          </Modal.Footer>
        </Modal>

        {!this.state.isLoading && this.renderPluginsList(this.state.plugins)}

        {this.state.pluginToInstallNow.uid ? <Redirect to={{pathname: '/install/', state: {plugin: this.state.pluginToInstallNow}}} push={true} />: ""}
      </div>
    );
  }

  render() {
    return (
      <div className="AddNew">
        {!this.state.isLoading ?
            this.renderPlugins():
            <LoaderSpinner loadingText="Loading Plugins ..."/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  }
}
const connectedAddNew = connect(mapStateToProps)(AddNew);
const sizedConnectedAddNew = sizeMe()(connectedAddNew);
export default withAlert()(sizedConnectedAddNew);