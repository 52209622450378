import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { configurePersistedStore } from './store'
import config from "./config";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/AlertTemplate'
import * as AccountHelper from './AccountHelper'

// Redux store to manage app states
const reduxPersistedStore = configurePersistedStore(AccountHelper.INITIAL_STATE)
const store = reduxPersistedStore.store
const persistor = reduxPersistedStore.persistor

// Amplify configurations
// Cognito support planned once federation will be setup.
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "account",
        endpoint: config.apiGatewayAccount.URL,
        region: config.apiGatewayAccount.REGION
      },
      {
        name: "pluginlist",
        endpoint: config.apiGatewayPluginList.URL,
        region: config.apiGatewayPluginList.REGION
      },
      {
        name: "pluginlistinstalled",
        endpoint: config.apiGatewayPluginListInstalled.URL,
        region: config.apiGatewayPluginListInstalled.REGION
      },
      {
        name: "plugininstall",
        endpoint: config.apiGatewayPluginInstall.URL,
        region: config.apiGatewayPluginInstall.REGION
      },
      {
        name: "pluginuninstall",
        endpoint: config.apiGatewayPluginUninstall.URL,
        region: config.apiGatewayPluginUninstall.REGION
      },
      {
        name: "pluginrequestaccess",
        endpoint: config.apiGatewayPluginRequestAccess.URL,
        region: config.apiGatewayPluginRequestAccess.REGION
      },
      {
        name: "pluginrequestcontent",
        endpoint: config.apiGatewayPluginListPM.URL,
        region: config.apiGatewayPluginListPM.REGION
      },
      {
        name: "messageslist",
        endpoint: config.apiGatewayMessagesList.URL,
        region: config.apiGatewayMessagesList.REGION
      },
      {
        name: "messagesstatus",
        endpoint: config.apiGatewayMessagesStatus.URL,
        region: config.apiGatewayMessagesStatus.REGION
      },
      {
        name: "fslist",
        endpoint: config.apiGatewayFSList.URL,
        region: config.apiGatewayFSList.REGION
      },
      {
        name: "fsdownloadzip",
        endpoint: config.apiGatewayFSDownloadZIP.URL,
        region: config.apiGatewayFSList.REGION
      },
      {
        name: "fsdownloadshare",
        endpoint: config.apiGatewayFSDownloadShare.URL,
        region: config.apiGatewayFSList.REGION
      },
      {
        name: "fsdownloadsharesaveas",
        endpoint: config.apiGatewayFSDownloadShareSaveAs.URL,
        region: config.apiGatewayFSList.REGION
      },
      {
        name: "fsdownloadaddtomyfiles",
        endpoint: config.apiGatewayFSDownloadAddToMyFiles.URL,
        region: config.apiGatewayFSList.REGION
      },
      {
        name: "fsmanagemkdir",
        endpoint: config.apiGatewayFSManageMkDir.URL,
        region: config.apiGatewayFSManageMkDir.REGION
      },
      {
        name: "fsmanagedelete",
        endpoint: config.apiGatewayFSManageDelete.URL,
        region: config.apiGatewayFSManageDelete.REGION
      },
      {
        name: "fsmanagerename",
        endpoint: config.apiGatewayFSManageRename.URL,
        region: config.apiGatewayFSManageRename.REGION
      },
      {
        name: "fsmanagecopy",
        endpoint: config.apiGatewayFSManageCopy.URL,
        region: config.apiGatewayFSManageCopy.REGION
      },
      {
        name: "fsmanageupload",
        endpoint: config.apiGatewayFSManageUpload.URL,
        region: config.apiGatewayFSManageUpload.REGION
      },
    ]
  },
});

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 10000,
  offset: '90px',
  transition: transitions.FADE
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <Router>
           <AlertProvider template={AlertTemplate} {...alertOptions}>
             <App />
           </AlertProvider>
        </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
