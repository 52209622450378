import { API } from "aws-amplify";
import log from "./logger"
import config from "./config";

export const SECURITYLEVEL_DEFAULT = 0;
export const SECURITYLEVEL_NONE = 1;
export const SYNCSUPPORT_DEFAULT = 0;
export const DELIVERYPOLICY_REGULAR = 0;
export const DELIVERYPOLICY_AUTO = 1;
export const CUSTOM_CATEGORY = "CUSTOM";

export const RECOMMEND_EMAIL_SUBJECT = "Recommending RepFiles"; // "Recommend a company to RepFiles"
export const RECOMMEND_EMAIL_CONTENT = "[Enter contact name here],%0D%0A%0D%0AI recommend that your company signs-up for RepFiles. RepFiles enables sales reps and distributors to access multiple companies' sales and marketing content inside a single app. %0D%0A%0D%0ABy having your sales collateral inside RepFiles, I can be sure that what I'm accessing is up-to-date, available for offline use and easy to share with customers.%0D%0A%0D%0AYou can contact support@repfiles.net to schedule an online demo. More information is also available at www.repfiles.net.%0D%0A%0D%0A";

const TAG = "PluginHelper"

export function isDefined(x) {
    return typeof (x) != 'undefined' && x !== null;
}

export function isDefinedNotEmpty(x) {
    return (isDefined(x) && (x.length > 0));
}

export function isPrivateSecure(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        if (plugin.securityLevel === SECURITYLEVEL_DEFAULT) {
            let categories = plugin.categories;
            if (isDefined(categories) && (categories === CUSTOM_CATEGORY)) {
                ret = true;
            }
        }
    }
    return ret;
}

export function isPublic(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        if (plugin.securityLevel === SECURITYLEVEL_NONE)
        {
            // Public Open: to everyone without authentication
            ret = true;
        }
    }
    return ret;
}

export function isPublicAutoDelivered(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        if (plugin.securityLevel === SECURITYLEVEL_NONE)
        {
            // Public Open: to everyone without authentication
            if (plugin.deliveryPolicy === DELIVERYPOLICY_AUTO) {
                // Auto-delivered
                ret = true;
            }
        }
    }
    return ret;
}

export function isPublicSecureAndAuthenticated(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        if (plugin.securityLevel === SECURITYLEVEL_DEFAULT)
        {
            if (plugin.authorizationStatus === true) {
                // Public Secure but authenticated for current repfiles.net user
                ret = true;
            }
        }
    }
    return ret;
}

export function isPublicSecure(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        if (plugin.securityLevel === SECURITYLEVEL_DEFAULT)
        {
            if (plugin.authorizationStatus === false)
            {
                // Public Secure. Not authenticated yet
                ret = true;
            }
        }
    }
    return ret;
}

export function isManageSupport(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        ret = plugin.manageSupport;
    }
    return ret;
}

export function isAuthenticated(plugin)
{
    let ret = false;
    if (isDefined(plugin))
    {
        ret = isPublicAutoDelivered(plugin) || isPublicSecureAndAuthenticated(plugin) || isPrivateSecure(plugin) || isManageSupport(plugin);
    }
    return ret;
}

// Remote service that load allowed plugins
function loadPluginsAllowed(token) {
    let init = {
        headers: {"Authorization": "Bearer " + token}
    }
    return API.get("pluginlist", "", init);
}

function loadPluginsInstalled(token) {
    let init = {
        headers: {"Authorization": "Bearer " + token}
    }
    return API.get("pluginlistinstalled", "", init);
}

function loadInstallPlugin(token, uid) {
    let init = {
        headers: {"Authorization": "Bearer " + token},
        queryStringParameters: {uid: uid},
    }
    return API.get("plugininstall", "", init);
}

function loadUninstallPlugin(token, uid) {
    let init = {
        headers: {"Authorization": "Bearer " + token},
        queryStringParameters: {uid: uid},
    }
    return API.get("pluginuninstall", "", init);
}

function loadRequestAccessPlugin(token, uid) {
    let init = {
        headers: {"Authorization": "Bearer " + token},
        queryStringParameters: {uid: uid},
    }
    return API.get("pluginrequestaccess", "", init);
}

function loadRequestContentPlugin(token, uid) {
    let init = {
        headers: {"Authorization": "Bearer " + token},
        queryStringParameters: {uid: uid},
    }
    return API.get("pluginrequestcontent", "", init);
}

/*export async function getPresignedUrl(absoluteFile) {
    let ret = {}
    try {
        // Temporary solution, remove me
        AWS.config.update({
          credentials: new AWS.Credentials ({
            accessKeyId: config.s3.KEYID,
             secretAccessKey: config.s3.KEYSECRET
          })
        })

        // Pre-signed
        const result = await Storage.get(absoluteFile.substring(1), {
          customPrefix:  {
            public: '',
          }
        });
        ret = result;
    } catch (e) {
      log.error(TAG, getPresignedUrl.name, e)
    }
    return ret;
}*/


// Overlay icons
function loadOverlayIcon(plugin) {
  if (plugin.installed === false) {
    // Top right icon
    if (isPrivateSecure(plugin)) {
      plugin.overlayIcon = "./images/greenlockkey.png";
    }
    else if (isPublicSecureAndAuthenticated(plugin)) {
      plugin.overlayIcon = "./images/greenunlock.png";
    }
    else if (isPublicSecure(plugin)) {
      plugin.overlayIcon = "./images/redlock.png";
    }
  }
  // Top left icon
  if (isManageSupport(plugin)) {
    plugin.adminIcon = "./images/admin.png";
  }
}

// Sort plugins by name. MyFiles first.
function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (a.uid === config.conf.MYFILES_PLUGIN.uid) {
        return -1;
    }
    if (b.uid === config.conf.MYFILES_PLUGIN.uid) {
        return 1;
    }
    if (nameA > nameB) return 1;
    else if (nameA < nameB) return -1;
    else return 0;
}

// Load Plugins for AddNew screen.
export async function loadPluginsAddNew(token) {
    let plugins = [];
    try {
      const payload = await loadPluginsAllowed(token);
      const pluginsAddNew = payload.pluginDetails;

      // Load installed plugins.
      const pluginsIntalled = await loadPluginsInstalled(token);

      // Generate icon URL and overlay icons
      for (let i=0; i < pluginsAddNew.length; i++) {
        const plugin = pluginsAddNew[i];
        plugin.installed = false;
        for (let j=0; j < pluginsIntalled.length; j++) {
            if (plugin.uid === pluginsIntalled[j]) {
                if (isPublicSecure(plugin) === false) {
                    plugin.installed = true;
                    break;
                }
            }
        }
        // Remove installed plugins.
        if ((!isAuthenticated(plugin)) && (plugin.installed === false)) {
          loadOverlayIcon(plugin);
          plugins.push(plugin);
        }
      }

      // Sort plugins by name. MyFiles first.
      plugins.sort(compare);

    } catch (e) {
      log.error(TAG, "loadPluginsAddNew", e);
      throw e;
    }
    return plugins;
}

// Load Plugins for Home screen.
export async function loadPluginsHome(token) {
    let plugins = [];
    try {
      const payload = await loadPluginsAllowed(token);
      const pluginsAllowed = payload.pluginDetails;

      // Load installed plugins.
      const pluginsIntalled = await loadPluginsInstalled(token);

      // Generate icon URL and overlay icons
      for (let i=0; i < pluginsAllowed.length; i++) {
        const plugin = pluginsAllowed[i];
        plugin.installed = false;
        for (let j=0; j < pluginsIntalled.length; j++) {
            if (plugin.uid === pluginsIntalled[j]) {
                // Public secure Plugin cannot be on home page.
                if (isPublicSecure(plugin) === false) {
                    plugin.installed = true;
                    loadOverlayIcon(plugin);
                    // Push installed plugin
                    plugins.push(plugin);
                    break;
                }
            }
        }
        if ((isAuthenticated(plugin)) && (plugin.installed === false)) {
          loadOverlayIcon(plugin);
          // Push allowed plugin (auto) but not installed.
          plugins.push(plugin);
        }
      }

      // Sort plugins by name. MyFiles first.
      plugins.sort(compare);

    } catch (e) {
      log.error(TAG, "loadPluginsHome", e);
      throw e;
    }
    return plugins;
}

export function isPluginInstalled(plugins, uid) {
    let ret = false;
    if (plugins) {
        for (let p=0; p < plugins.length; p++) {
            if ((plugins[p].uid === uid) && (plugins[p].installed === true)){
                ret = true;
                break;
            }
        }
    }
    return ret;
}

// Install plugin.
export async function installPlugin(token, uid) {
    let plugin = {};
    try {
       plugin = await loadInstallPlugin(token, uid);
    } catch (e) {
      log.error(TAG, "installPlugin", e);
      throw e;
    }
    return plugin;
}

// Uninstall plugin.
export async function uninstallPlugin(token, uid) {
    let plugin = {};
    try {
       plugin = await loadUninstallPlugin(token, uid);
    } catch (e) {
      log.error(TAG, "uninstallPlugin", e);
      throw e;
    }
    return plugin;
}

// Request Access to plugin.
export async function requestAccessPlugin(token, uid) {
    let plugin = {};
    try {
       plugin = await loadRequestAccessPlugin(token, uid);
    } catch (e) {
      log.error(TAG, "requestAccessPlugin", e);
      throw e;
    }
    return plugin;
}

// Request Content for a plugin.
export async function requestContentPlugin(token, uid) {
    let pluginManagers = {};
    try {
       pluginManagers = await loadRequestContentPlugin(token, uid);
    } catch (e) {
      log.error(TAG, "requestContentPlugin", e);
      throw e;
    }
    return pluginManagers;
}
