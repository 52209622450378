import React, { Component } from "react";
import { Form } from "react-bootstrap";
import log from "../logger"
import { updateBrowserCWD } from '../actions/actionCreators'
import { connect } from 'react-redux'
// import {  } from 'yenc';
import "./Uploader.css";

const TAG = "Uploader"

class Uploader extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
      };

      this.fileInput = React.createRef();
      this.folderInput = React.createRef();

    /* Prototypes: Static functions - String helpers */
    if (!String.prototype.format) {
        String.prototype.format = function() {
            var args = arguments;
            return this.replace(/\{(\d+)\}/g, function(match, number) {
                return typeof args[number] != 'undefined' ? args[number] : match;
            });
        };
    }

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }

    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(searchString, position) {
            var subjectString = this.toString();
            if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.indexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        };
    }

      // Extra parameters
      this.extraParameters = {}
      this.KEYS = {
            FILENAME : "filename",
            RELATIVEFILENAME : "relativefilename",
            ACCOUNT : "account",
            ACCOUNTCREATION : "accountcreation",
            DIRECTORY : "directory",
            EMPTYDIRECTORY : "emptydirectory",
            HEADEXTRAPARAMETERS : "headextraparameters",
            FILETIMESTAMP : "filetimestamp",
            ERRORHEADER : "errorheader",
            ENCODEHEADERS : "encodeheaders"
        }

      this.monitor = {
         addFileCancelled: false,
         transferInProgress: false,
         retryAttempt: 0,
         totalFilesSize: 0,
         files: [],
         filesTranferred: [],
         // Optional S3 multipart support
         uploadId: "",
         partETags: [],
      }

      this.eventListeners = {};

       // I18N resources, date format, unit ...
       this.i18n = {
            resources : {
                "progresspanel.button.select.files" : "Add Files",
                "progresspanel.button.select.folder" : "Add Folder",
                "progresspanel.button.start" : "Start",
                "progresspanel.button.cancel" : "Cancel",
                "progresspanel.empty.label" : "No item selected",
                "progresspanel.selection.label" : "{0} item{1} selected for upload ({2})",
                "progresspanel.details.reset.label" : "&nbsp;",
                "progresspanel.details.label" : "{0} item{1} left on {2} selected",
                "progresspanel.details.retry.label" : ", attempt {0}/{1}",
                "containerempty.welcome.label" : "Drag and drop<br><br>or select files",
                "containerempty.done.label" : "Transfer successful<br>{0} item{1} uploaded",
                "taskoutput.upload.failed.maxfiles.error" : "Cannot add more files, maximum amount of files exceeds limit of <span class='jfubold jfufail'>{0}</span>",
                "taskoutput.upload.failed.maxsize.error" : "Cannot add more files, maximum total size exceeds limit of <span class='jfubold jfufail'>{0}</span>",
                "taskoutput.upload.failed.maxfilesize.error" : "{0} is <span class='jfubold jfufail'>{1}</span> and exceeds size limit of <span class='jfubold'>{2}</span>",
                "taskoutput.upload.failed.blacklist.error" : "<span class='jfubold jfufail'>{0}</span> is not allowed",
                "taskoutput.upload.failed.whitelist.error" : "<span class='jfubold jfufail'>{0}</span> is not allowed",
                "taskoutput.upload.failed.duplicate.error" : "'{0}' already exists remotely.",
                "taskoutput.upload.failed.duplicate.skip" : "'{0}' already exists remotely, skipping.",
                "taskoutput.upload.failed" : "Upload failed for '{0}' (network error) on state {1}",
                "taskoutput.upload.timeout" : "Upload timed out '{0}'",
                "taskoutput.upload.starting" : "Upload starting '{0}'",
                "taskoutput.upload.started" : "Upload started '{0}'",
                "taskoutput.upload.completed" : "Upload completed '{0}'",
                "taskoutput.upload.progress" : "Uploading '{0}' {1}% ({2}/{3})",
                "taskoutput.upload.completing" : "Completing upload '{0}' {1}% ({2})",
                "taskoutput.upload.notcompleted" : "Upload not completed '{0}', {1} ({2})",
                "taskoutput.upload.cancelled" : "Upload cancelled '{0}'",
                "taskoutput.upload.done" : "Upload done",
                //JFU
                "taskoutput.upload.info.connecting" : "<span class='jfuminor'>Connecting...</span>",
                "taskoutput.upload.info.started" : "Uploading: <span class='jfubold'>{0}</span> ({1})",
                "taskoutput.upload.info.completed" : "Upload completed",
                "taskoutput.upload.info.completing" : "<span class='jfuminor'>Completing upload...</span>",
                "taskoutput.upload.info.cancelled" : "Upload cancelled",
                "taskoutput.upload.info.failed" : "<span class='jfubold jfufail'>Upload failed</span>",
                "taskoutput.upload.info.done" : "Transfer successful: <span class='jfubold'>{0} item{1} uploaded</span>",
                //JFU//
                "queue.file.add.error" : "Cannot add file '{0}'",
                "queue.file.remove.label" : "Remove",
                "queue.file.pathtooltip" : "true",
                "queue.file.filename.tree.label" : "{0} [{1} item{2}]",
                "queue.file.filedate.label" : "{0} {1}",
                "queue.table.column.filename.title" : "&nbsp;Filename",
                "queue.table.column.size.title" : "&nbsp;Size",
                "queue.table.column.date.title" : "&nbsp;Date",
                "queue.table.column.remove.link" : "&nbsp;All",
                "queue.table.column.remove.title" : "Remove all",
                "queue.table.structure" : "tree",
                "dialog.close.tooltip" : "Close",
                "dialog.overwrite.title" : "<h2>Overwrite remote item?</h2>",
                "dialog.overwrite.identical.label" : "<p>The item '{0}' ({1}) you are trying to upload already exists remotely.</p><p>Do you want to overwrite, skip or abort transfer?</p>",
                "dialog.overwrite.differ.label" : "<p>The item '{0}' ({1}) you are trying to upload already exists remotely but content differs.</p><p>Do you want to overwrite, skip or abort transfer?</p>",
                "dialog.overwrite.button.overwrite" : "Overwrite",
                "dialog.overwrite.button.skip" : "Skip",
                "dialog.overwrite.button.abort" : "Abort"
            },
            sizeunits : [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB' ]
        };

        /* JavaScript API */
       this.CALLBACKS = {
            JSINITIALIZED : "onJSInitialized", /* event.detail is {version : version} */
            NEWFILEACCEPTED : "onNewFileAccepted", /* Called after file has been added to queue. event.detail is {tfile : TFile} */
            FILEREMOVEDFROMQUEUE : "onFileRemovedFromQueue", /* Called after file has been removed from queue. event.detail is {tfile : TFile, source: ui/monitor} */
            ALLFILESREMOVEDFROMQUEUE : "onAllFilesRemovedFromQueue", /* Called after queue is cleared (remove all action). event.detail is {} */
            INFOSTARTED : "onInfoStarted", /* Called on HEAD request for resume. event.detail is {tfile : file, retryAttempt : currentAttempt} */
            INFOENDED : "onInfoEnded", /* Once HEAD request ended. event.detail is {tfile : file, status : HTTP status, remoteSize : remoteSize, retryAttempt : currentAttempt} */
            UPLOADSTARTED : "onUploadStarted", /* Called when HTTP POST/PUT chunk upload started. event.detail is {tfile : TFile, startByte : startByte, endByte : endByte, retryAttempt : currentAttempt} */
            UPLOADTIMEOUT : "onUploadTimeout", /* Called after retry attempts. Same event.detail */
            UPLOADCANCELLED : "onUploadCancelled", /* Called when transfer is aborted. Same event.detail */
            UPLOADCOMPLETING : "onUploadCompleting", /* Called after 100% upload reached {tfile : TFile, startByte : startByte, endByte : endByte, full : true/false, retryAttempt : currentAttempt} */
            UPLOADCOMPLETED : "onUploadCompleted", /* Called after each chunk transfer, full=true means final chunk. event.detail is {tfile : TFile, startByte : startByte, endByte : endByte, full : true/false, retryAttempt : currentAttempt} */
            UPLOADFAILED : "onUploadFailed", /* Called after retry attempts. event.detail is {tfile : TFile, startByte : startByte, endByte : endByte, status : HTTP Status,, retryAttempt : currentAttempt} */
            TRANSFERABORTED : "onTransferAborted", /* Called when overall transfer is cancelled. event.detail is {files : transferredFiles[TFile]} */
            TRANSFERDONE : "onTransferDone", /* Called when overall transfer is done successfully. event.detail is {files : transferredFiles[TFile]} */
            FINALPOSTSTARTED : "onFinalPostStarted", /* Called on final POST request after transfer. event.detail is {files : transferredFiles[TFile]} */
            FINALPOSTENDED : "onFinalPostEnded" /* Once final POST request ended. event.detail is {status : HTTP status} */
        };
       this.JSAPI = [ this.CALLBACKS.JSINITIALIZED, this.CALLBACKS.NEWFILEACCEPTED, this.CALLBACKS.FILEREMOVEDFROMQUEUE, this.CALLBACKS.ALLFILESREMOVEDFROMQUEUE, this.CALLBACKS.INFOSTARTED,
                       this.CALLBACKS.INFOENDED, this.CALLBACKS.UPLOADSTARTED, this.CALLBACKS.UPLOADCOMPLETING, this.CALLBACKS.UPLOADCOMPLETED, this.CALLBACKS.UPLOADFAILED,
                       this.CALLBACKS.UPLOADTIMEOUT, this.CALLBACKS.UPLOADCANCELLED, this.CALLBACKS.TRANSFERABORTED, this.CALLBACKS.TRANSFERDONE, this.CALLBACKS.FINALPOSTSTARTED, this.CALLBACKS.FINALPOSTENDED];

      log.info(TAG, this)
    }

    addEventListener = (type, method, scope, context) => {
        var listeners, handlers;
        if (!(listeners = this.eventListeners)) {
            listeners = this.eventListeners = {};
        }
        if (!(handlers = listeners[type])) {
            handlers = listeners[type] = [];
        }
        scope = (scope ? scope : window);
        handlers.push({
            method : method,
            scope : scope,
            context : (context ? context : scope)
        });
        this.logMessage(listeners, 3);
    };

    fireEvent = (type, data, context) => {
        var listeners, handlers, i, n, handler; //, scope;
        if (!(listeners = this.eventListeners)) {
            return;
        }
        if (!(handlers = listeners[type])) {
            return;
        }
        for (i = 0, n = handlers.length; i < n; i++) {
            handler = handlers[i];
            if (typeof (context) !== "undefined" && context !== handler.context) {
                continue;
            }
            // Create custom event and pass data structure under event.detail
            var event = document.createEvent('CustomEvent');
            event.initCustomEvent(type, true, true, data);
            if (handler.method.call(handler.scope, this, event) === false) {
                return false;
            }
        }
        return true;
    };

    isDefined(x) {
      return typeof (x) != 'undefined' && x !== null;
    }

    isDefinedNotEmpty(x) {
      return (this.isDefined(x) && (x.length > 0));
    }

    isDefinedPositive(x) {
        return (this.isDefined(x) && (x > 0));
    }

    logMessage(msg, level) {
      if (this.isDefined(this.props.loglevel) && (level <= this.props.loglevel)) {
          log.info(TAG, msg);
      }
    }

    getUIElement = function(identifier) {
      if (this.isDefinedNotEmpty(document.containeruid)) {
          return document.getElementById(document.containeruid).querySelector("#" + identifier);
      }
      else {
          return document.getElementById(identifier);
      }
    };

    toArray(list) {
        return Array.prototype.slice.call(list || [], 0);
    }

    errorHandler(e) {
        this.logMessage("FS error: " + e.message, 1);
        this.newFileFailed(e);
    }

    isInputFolderSupported(tmpInput) {
        if ((tmpInput) && this.isDefined(tmpInput.webkitdirectory)) {
            return true;
        }
        return false;
    }

    allFeaturesSupport = () => {
        var ret = ((typeof (File) !== 'undefined') && (typeof (Blob) !== 'undefined') && (typeof (FileList) !== 'undefined') && (!!Blob.prototype.webkitSlice || !!Blob.prototype.mozSlice || !!Blob.prototype.slice || false));
        return ret;
    };

    bytesToSize = (bytes) => {
        var sizes = this.i18n.sizeunits;
        var decimals = 1;
        if (bytes <= 0) {
            return bytes + ' ' + sizes[0];
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (i === 0) {
            decimals = 0;
        }
        return (bytes / Math.pow(1024, i)).toFixed(decimals) + ' ' + sizes[i];
    };

    getTopPath = (p) => {
        var ret = "";
        if ((this.isDefinedNotEmpty(p)) && (p !== "/")) {
            var slashFirstIndex = p.indexOf("/");
            if (slashFirstIndex > 0) {
                ret = p.substring(0, slashFirstIndex + 1);
            }
        }
        return ret;
    };

    fileHashCodePart(file, partid) {
        var hashPart = 0;
        if (this.isDefined(file)) {
            if (partid === 1) {
                hashPart = this.strHashCode(file.path + file.name + "/" + file.size);
            }
            else if (partid === 2) {
                var topPath = this.getTopPath(file.path);
                if (this.isDefinedNotEmpty(topPath)) {
                    hashPart = this.strHashCode(topPath);
                }
            }
        }
        return hashPart;
    }

    fileHashCode(file) {
        var hashPart1 = this.fileHashCodePart(file, 1);
        var hashPart2 = this.fileHashCodePart(file, 2);
        return ("i" + hashPart1 + "_" + hashPart2);
    }

    strHashCode(str) {
        var hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (var i = 0; i < str.length; i++) {
            var ch = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + ch;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    // We need to check file is readable under FF or Safari
    // because folder can be added even if not supported!
    addFileIfReadable(file, depth, parentFolder) {
        try {
            var reader = new FileReader();
            reader.onprogress = function(e) {
                this.logMessage(e, 3);
                // Prevent large file to be read.
                reader.abort();
            };
            // https://w3c.github.io/FileAPI/#dfn-loadend-event
            reader.onloadend = function(e) {
                this.logMessage(e, 3);
                // FF does not call onprogress for empty file. onloadstart is called for folder, so we use onloadend instead.
                // onloadend called for folder under Safari with error != null
                // IE11: AbortError in name property of reader.error
                if ((!this.isDefined(reader.error)) || ((this.isDefined(reader.error.message) && (reader.error.message.length === 0))) || ((this.isDefined(reader.error.name) && (reader.error.name === "AbortError")))) {
                    this.newFileCompleted(file, depth, parentFolder);
                }
            };
            reader.onerror = function(e) {
                this.newFileReadFailed(e);
            };
            reader.onabort = function(e) {
                this.logMessage("Read abort: " + file.name, 3);
            };
            reader.readAsArrayBuffer(file);
        }
        catch (ex) {
            // FF returns undefined for some files (from quick access under Windows).
            this.logMessage("File is not defined: " + ex, 1);
        }
    }

    // Read FileSystem items recursively and asynchronously
    readFSEntriesAsync = (items) => {
        if ((items) && (items.length > 0)) {
            for (var i = 0; i < items.length; i++) {
                if (this.monitor.addFileCancelled === true) {
                    break;
                }
                var entry = items[i];
                if ('webkitGetAsEntry' in items[i]) {
                    // Chrome returns null for some files (from quick access under Windows)?
                    entry = entry.webkitGetAsEntry();
                }
                if (this.isDefined(entry)) {
                    if (entry.isDirectory === true) {
                        var initItems = [];
                        initItems.push(entry);
                        this.readDirectory(initItems, 0, entry);
                    }
                    else {
                        // Convert from FileEntry to File API.
                        if ('file' in entry) {
                            var successCallback = (f) => {this.newFileCompleted(f, 0, null);}
                            entry.file(successCallback, this.errorHandler);
                        }
                        else if ('getAsFile' in entry) {
                            this.newFileCompleted(entry.getAsFile(), 0, null);
                        }
                    }
                }
                else {
                    this.logMessage("Entry not defined", 2);
                }
            }
        }
    }

    // Recursive directory read
    readDirectory = (entries, depth, parentFolder) => {
        for (var l = 0; l < entries.length; l++) {
            if (this.monitor.addFileCancelled === true) {
                break;
            }
            var entry = entries[l];
            if (entry.isDirectory === true) {
                if ((this.props.folderdepth >= 0) && (depth >= this.props.folderdepth)) {
                    continue;
                }
                var directoryReader = entry.createReader();
                this.getAllEntries(directoryReader, this.readDirectory, (depth + 1), entry);
            }
            else {
                // Convert from FileEntry to File API.
                if ('file' in entry) {
                    var completedCallback = (f) => {this.newFileCompleted(f, depth, parentFolder);}
                    entry.file(completedCallback, this.errorHandler);
                }
                else if ('getAsFile' in entry) {
                    this.newFileCompleted(entry.getAsFile(), depth, parentFolder);
                }
            }
        }
    }

    // This is needed to get all directory entries as one
    // call of readEntries may not return all items. Works a bit like stream reader.
    getAllEntries(directoryReader, callback, depth, parentFolder) {
        var entries = [];
        var readEntries = () => {
            var successReadCallback = (results) => {
                  if (!results.length) {
                      // Directory read completed, sort, count and notify (to detect empty folders)
                      entries.sort();
                      var totalDirectories = 0;
                      var totalFiles = 0;
                      for (var l = 0; l < entries.length; l++) {
                          if (entries[l].isDirectory === true) {
                              totalDirectories = totalDirectories + 1;
                          }
                          else {
                              totalFiles = totalFiles + 1;
                          }
                      }
                      this.newFolderListCompleted(totalFiles, totalDirectories, depth, parentFolder);
                      callback(entries, depth, parentFolder);
                  }
                  else {
                      entries = entries.concat(this.toArray(results));
                      readEntries();
                  }
              }
            directoryReader.readEntries(successReadCallback, this.errorHandler);
        };
        readEntries();
    }

    /* EVENTS */

    // New file available.
    newFileCompleted(file, depth, parentFolder) {
        this.logMessage(file, 2);
        if (this.monitor.addFileCancelled === true) {
            return;
        }
        var progressInfoDetails = this.getUIElement("jfu-progressinfodetails");
        // By file requirements
        var requirementMessage = this.checkFileRequirements(file);
        if (requirementMessage.length === 0) {
            // All files requirements
            requirementMessage = this.checkAllFilesRequirements(file);
            if (requirementMessage.length === 0) {
                //var tfile = new TFile();
                //tfile.withFile(file, parentFolder);
                this.addFileInQueue(file);
            }
            else {
                // Stop adding file now.
                this.monitor.addFileCancelled = true;
                progressInfoDetails.innerHTML = requirementMessage;
            }
        }
        else {
            progressInfoDetails.innerHTML = requirementMessage;
            if (this.isDefinedNotEmpty(this.props.policy) && (this.props.policy === "abort")) {
                this.monitor.addFileCancelled = true;
            }
        }
    }

    /* File Utils */

    checkFileRequirements(file) {
        var ret = "";
        if (this.isDefinedPositive(this.props.maxfilesize) && (file.size > this.props.maxfilesize)) {
            ret = this.i18n.resources["taskoutput.upload.failed.maxfilesize.error"].format(file.name, this.bytesToSize(file.size), this.bytesToSize(this.props.maxfilesize));
        }
        if (this.isDefinedNotEmpty(this.props.blacklist)) {
            // Case insensitive regex
            var re = new RegExp(this.props.blacklist, "i");
            if (re.test(file.name)) {
                ret = this.i18n.resources["taskoutput.upload.failed.blacklist.error"].format(file.name);
                return ret;
            }
        }
        if (this.isDefinedNotEmpty(this.props.whitelist)) {
            // Case insensitive regex
            var re = new RegExp(this.props.whitelist, "i");
            if (!re.test(file.name)) {
                ret = this.i18n.resources["taskoutput.upload.failed.whitelist.error"].format(file.name);
                return ret;
            }
        }
        return ret;
    }

    checkAllFilesRequirements(file) {
        var ret = "";
        if (this.isDefinedPositive(this.props.maxfiles) && (this.monitor.files.length >= this.props.maxfiles)) {
            ret = this.i18n.resources["taskoutput.upload.failed.maxfiles.error"].format(this.props.maxfiles);
            return ret;
        }
        if (this.isDefinedPositive(this.props.maxsize) && ((this.monitor.totalFilesSize + file.size) >= this.props.maxsize)) {
            ret = this.i18n.resources["taskoutput.upload.failed.maxsize.error"].format(this.bytesToSize(this.props.maxsize));
            return ret;
        }
        return ret;
    }

    addFileInQueue(file) {
        // file is TFile here.
        // Generate file.altname if template parameter enabled.
        //renameFile(file);
        this.monitor.files.push(file);
        this.monitor.totalFilesSize = this.monitor.totalFilesSize + file.size;
        //var rowIdentifier = fileHashCode(file);
        //var rowsDiv = $.getUIElement("jbu-filepanelitems_list");

        /*if (isDefined(rowsDiv)) {
            if (($.i18n.resources["queue.table.structure"] == "flat")) {
                // Flat structure
                var rowLi = createItemRow(file, rowIdentifier);
                rowsDiv.appendChild(rowLi);
            }
            else {
                // Tree structure
                var topPath = file.getTopPath(file.path);
                if (isDefinedNotEmpty(topPath)) {
                    // Check if we have row for this topPath by requesting file hashcode part2 pattern.
                    var matchingRowPattern = "[id$='_" + fileHashCodePart(file, 2) + "']";
                    var rowLi = document.querySelectorAll(matchingRowPattern);
                    if (isDefined(rowLi) && (rowLi.length > 0)) {
                        // Found, update row items/size
                        updateItemRow(rowLi[0], file, true);
                    }
                    else {
                        // New top folder, set total items/size in data attributes, with specific hashcode for one-step removal.
                        rowLi = createItemRow(file, "_" + fileHashCodePart(file, 2));
                        updateItemRow(rowLi, file, true);
                        rowsDiv.appendChild(rowLi);
                    }
                }
                else {
                    // Same as flat structure
                    var rowLi = createItemRow(file, rowIdentifier);
                    rowsDiv.appendChild(rowLi);
                }
            }
        }*/

        // Progress info
        this.updateProgressInfo();
        //this.updateHeadersLayout();
        this.fireEvent(this.CALLBACKS.NEWFILEACCEPTED, {
            tfile : file
        });
    }

    // New folder list completed.
    newFolderListCompleted(totalFiles, totalDirectories, depth, parentFolder) {
        if (this.monitor.addFileCancelled === true) {
            return;
        }
        // Empty directory support
        /*if ((this.isDefinedNotEmpty(this.extraParameters[this.KEYS.EMPTYDIRECTORY])) && (this.extraParameters[this.KEYS.EMPTYDIRECTORY] == "true") && (totalDirectories === 0) && (totalFiles === 0) && (this.isDefined(parentFolder))) {
            this.logMessage("Empty folder: " + parentFolder + ", depth: " + depth, 2);
            // Callback function to get additional metadata (if any)
            var newEmptyFolder = function(parentFolder, lastModified) {
                var tfile = new TFile();
                tfile.withEmptyFolder(parentFolder, lastModified);
                // All files requirements
                var requirementMessage = this.checkAllFilesRequirements(tfile);
                if (requirementMessage.length === 0) {
                    this.addFileInQueue(tfile);
                }
                else {
                    // Stop adding file now.
                    this.monitor.addFileCancelled = true;
                    var progressInfoDetails = this.getUIElement("jfu-progressinfodetails");
                    progressInfoDetails.innerHTML = requirementMessage;
                }
            };
            if (this.isDefined(parentFolder.getMetadata)) {
                parentFolder.getMetadata(function(metadata) {
                    newEmptyFolder(parentFolder, metadata.modificationTime);
                });
            }
            else {
                newEmptyFolder(parentFolder, null);
            }
        }*/
    }

    // New file cannot be added.
    newFileFailed(e) {
        var progressInfoDetails = this.getUIElement("jfu-progressinfodetails");
        progressInfoDetails.innerHTML = this.i18n.resources["queue.file.add.error"].format(e.name);
    }

    // We get here if file cannot by read for any reason (security, file locked, folder selected)
    newFileReadFailed(e) {
        this.logMessage(e, 2);
    }

    uploadFile(file, startOffset, stopOffset) {
        if (this.monitor.transferCancelled === true) {
            this.updateUIOnCancellation(file);
            return;
        }
        // Any chunk?
        var startByte = (Number(startOffset) > 0) ? Number(startOffset) : 0;
        var endByte = (Number(stopOffset) > 0) ? Number(stopOffset) : file.size;
        if (this.isDefinedPositive(this.props.chunksize)) {
            if (endByte > (startByte + this.props.chunksize)) {
                endByte = (startByte + this.props.chunksize);
            }
            if (endByte > file.size) {
                endByte = file.size;
            }
        }
        this.logMessage("Uploading: " + file.name + " with range [" + startByte + "/" + endByte + "]", 1);
        var xhr = new XMLHttpRequest();
        // Add and wrap event listeners: https://xhr.spec.whatwg.org/
        // Upload requires CORS (OPTIONS verb enabled for preflight)
        xhr.addEventListener("loadstart", (e) => {
            this.uploadStarted(e, file, this, startByte, endByte);
        }, false);
        xhr.upload.addEventListener("progress", (e) => {
            this.uploadProgress(e, file, this, startByte, endByte);
        }, false);
        xhr.addEventListener("load", (e) => {
            this.uploadCompleted(e, file, xhr, startByte, endByte);
        }, false);
        xhr.addEventListener("error", (e) => {
            this.uploadFailed(e, file, this, startByte, endByte);
        }, false);
        xhr.addEventListener("timeout", (e) => {
            this.uploadTimedOut(e, file, this, startByte, endByte);
        }, false);
        xhr.addEventListener("abort", (e) => {
            this.uploadCancelled(e, file, this, startByte, endByte);
        }, false);
        xhr.addEventListener("readystatechange", (e) => {
            this.uploadStateMonitor(e, file, this, startByte, endByte);
        }, false);
        // Controller: Cancel transfer
        var cancelTransferButton = this.getUIElement("jfu-canceltransfer");
        cancelTransferButton.onclick = () => {
            xhr.abort();
            this.monitor.transferCancelled = true;
        };
        /*if (this.isDefined(extraParameters.httpmethod) && (extraParameters.httpmethod.toLowerCase() === "put")) {
            uploadFilePUT(file, xhr, startByte, endByte);
        }
        else {
            uploadFilePOST(file, xhr, startByte, endByte);
        }*/
        this.uploadFilePOST(file, xhr, startByte, endByte);
    }

    // Multipart POST upload
    uploadFilePOST(file, xhr, startByte, endByte) {
        // var s3AddOnEnabled = false;
        // Extra parameters
        var fd = new FormData();
        fd.append("to", JSON.stringify(this.props.browserContent.folder));
        /*for ( var k in extraParameters) {
            var v = extraParameters[k];
            if ((k == KEYS.RELATIVEFILENAME) && (v == "true")) {
                v = file.path + file.altname;
            }
            else if ((k == KEYS.EMPTYDIRECTORY) && (v == "true")) {
                if (file.isFile === false) {
                    v = file.path + file.name;
                }
                else {
                    continue;
                }
            }
            else if ((k == KEYS.FILETIMESTAMP) && (v == "true")) {
                v = file.lastModifiedDate.getTime();
            }
            if (k != KEYS.ERRORHEADER) {
                // Only a few extra parameters allowed for S3.
                if (s3AddOnEnabled === true) {
                    for (var s3k in S3KEYS) {
                        if ((S3KEYS[s3k].toLowerCase() == k.toLowerCase()) || (k == KEYS.RELATIVEFILENAME)) {
                            if (k == KEYS.RELATIVEFILENAME) {
                                // relativefilename to key with optional prefix
                                k = S3KEYS.KEY;
                                if (isDefinedNotEmpty(extraParameters[KEYS.ACCOUNT])) {
                                    v = file.normalizeSeparator(file.normalizePath(extraParameters[KEYS.ACCOUNT]) + v);
                                }
                            }
                            fd.append(k, v);
                            break;
                        }
                    }
                }
                else {
                    fd.append(k, v);
                }
            }
        }*/
        // Multipart asynchronous upload
        var url = this.getUniqueURL(this.props.url);
        // No parameter on query string allowed.
        /*if (s3AddOnEnabled === true) {
            url = this.props.url;
            // Add Content-Type automatically
            var s3ContentType = "application/octet-stream";
            if (file.type.length > 0) {
                s3ContentType = file.type;
            }
            //fd.append(S3KEYS.CONTENTTYPE, s3ContentType);
        }*/
        xhr.open("POST", url, true);
        this.addRegularRequestHeader(xhr);
        if ((startByte > 0) || (endByte < file.size)) {
            // Additional meta-data for S3 multipart ssupport
            fd.append("chunksize", this.props.chunksize);
            fd.append("uploadId", this.monitor.uploadId);
            fd.append("partETags", JSON.stringify(this.monitor.partETags));
            // Apply slice
            var bytesBlob = file.slice(startByte, endByte, file.type);
            var contentRange = "bytes " + startByte + "-" + endByte + "/" + file.size;
            this.logMessage("POST: Blob created for " + file.name + ": " + contentRange + " " + bytesBlob.type, 2);
            xhr.setRequestHeader("Content-Range", contentRange);
            fd.append(this.props.paramfile, bytesBlob, file.name);
            xhr.send(fd);
        }
        else {
            if (file.isFile === false) {
                // slice not supported for directory.
                fd.append(this.props.paramfile, file.file);
            }
            else {
                // Force updated Content-Type (instead of original fd.append($.props.paramfile, file.file);)
                fd.append(this.props.paramfile, file.slice(0, file.size, file.type), file.name); //file.altname
            }
            xhr.send(fd);
        }
    }

    addRegularRequestHeader(xhr) {
        try {
            xhr.setRequestHeader("Authorization", "Bearer " + this.props.account.token);
            if (this.isDefinedNotEmpty(this.props.username) && this.isDefinedNotEmpty(this.props.password)) {
                xhr.setRequestHeader("Authorization", "Basic " + btoa(this.props.username + ":" + this.props.password));
            }
        }
        catch (ex) {
            this.logMessage("setRequestHeader error: " + ex, 1);
        }
    }

    // Called on multipart POST request start
    uploadStarted(e, file, xhr, startByte, endByte) {
        this.lockButtonsPanel(false);
        this.enableSpinner(false);
        if (this.isDefinedPositive(this.props.chunksize) && (((file.size - startByte) > this.props.chunksize) || (file.size === endByte))) {
            // Slice enabled with at least one chunk.
        }
        else {
            this.resetProgressBar();
            var progressInfo = this.getUIElement("jfu-progressinfo");
            progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.started"].format(file.name);
        }
        var progressInfoDetails = this.getUIElement("jfu-progressinfodetails");
        var remainingFiles = this.monitor.files.length;
        var msg = this.i18n.resources["progresspanel.details.label"].format(remainingFiles, remainingFiles > 1 ? "s" : "", (remainingFiles + this.monitor.filesTranferred.length));
        if (this.isDefinedPositive(this.props.retry) && (this.monitor.retryAttempt > 0)) {
            msg = msg + this.i18n.resources["progresspanel.details.retry.label"].format(this.monitor.retryAttempt, this.props.retry);
        }
        progressInfoDetails.innerHTML = msg;
        this.fireEvent(this.CALLBACKS.UPLOADSTARTED, {
            tfile : file,
            startByte : startByte,
            endByte : endByte,
            retryAttempt : this.monitor.retryAttempt
        });
    }

    // Called on multipart POST request success
    uploadCompleted(e, file, xhr, startByte, endByte) {
        var progressInfo = this.getUIElement("jfu-progressinfo");
        // 200 = OK, 201 = CREATED, 204 = NO_CONTENT
        if ((xhr.status === 200) || (xhr.status === 201) || (xhr.status === 204)) {
            var eventDetail = {
                tfile : file,
                startByte : startByte,
                endByte : endByte,
                full : false,
                retryAttempt : this.monitor.retryAttempt
            };
            this.monitor.retryAttempt = 0;
            // Save optional S3 multipart parameters.
            var jsonResponse = JSON.parse(xhr.responseText);
            this.monitor.uploadId = jsonResponse.uploadId? jsonResponse.uploadId : "";
            this.monitor.partETags = jsonResponse.partETags? jsonResponse.partETags: [];
            // Is it slice or really completed?
            if (endByte === file.size) {
                // Yes, completed. Keep file transferred
                this.monitor.filesTranferred.push(file);
                progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.completed"].format(file.name);
                eventDetail.full = true;
                this.fireEvent(this.CALLBACKS.UPLOADCOMPLETED, eventDetail);
                this.removeFileFromQueue(file, false, "monitor");
                // Must be the last command if we continue, uploadDone called here.
                this.continueQueuedUpload();
            }
            else {
                // No, continue slice process.
                this.fireEvent(this.CALLBACKS.UPLOADCOMPLETED, eventDetail);
                this.uploadFile(file, endByte, -1);
            }
        }
        else {
            if (this.retryTransferOnError(file, startByte, endByte) === true) {
                this.lockButtonsPanel(true);
                this.enableSpinner(false);
                // No more retry, report error
                var errorMessage = this.i18n.resources["taskoutput.upload.notcompleted"].format(file.name, xhr.statusText, xhr.status);
                // with a custom message generated on server?
                /*if (this.isDefinedNotEmpty(extraParameters[KEYS.ERRORHEADER])) {
                    var errorHeader = xhr.getResponseHeader(extraParameters[KEYS.ERRORHEADER]);
                    if (this.isDefinedNotEmpty(errorHeader)) {
                        errorMessage = errorHeader;
                    }
                }*/
                progressInfo.innerHTML = errorMessage;
                if (this.isDefined(this.props.resetprogressbar) && (this.props.resetprogressbar === true)) {
                    this.resetProgressBar();
                }
                this.fireEvent(this.CALLBACKS.UPLOADFAILED, {
                    tfile : file,
                    startByte : startByte,
                    endByte : endByte,
                    status : xhr.status,
                    retryAttempt : this.monitor.retryAttempt
                });
            }
        }
    }

    // Called on multipart POST request error (network level)
    uploadFailed(e, file, xhr, startByte, endByte) {
        if (this.retryTransferOnError(file, startByte, endByte) === true) {
            // No more retry, report error
            this.lockButtonsPanel(true);
            this.enableSpinner(false);
            // Could be connection refused (too large transfer)
            var progressInfo = this.getUIElement("jfu-progressinfo");
            progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.failed"].format(file.name, xhr.readyState);
            if (this.isDefined(this.props.resetprogressbar) && (this.props.resetprogressbar === true)) {
                this.resetProgressBar();
            }
            this.fireEvent(this.CALLBACKS.UPLOADFAILED, {
                tfile : file,
                startByte : startByte,
                endByte : endByte,
                status : xhr.status,
                retryAttempt : this.monitor.retryAttempt
            });
        }
    }

    // Called on multipart POST request error
    uploadTimedOut(e, file, xhr, startByte, endByte) {
        if (this.retryTransferOnError(file, startByte, endByte) === true) {
            // No more retry, report error
            this.lockButtonsPanel(true);
            this.enableSpinner(false);
            var progressInfo = this.getUIElement("jfu-progressinfo");
            progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.timeout"].format(file.name);
            if (this.isDefined(this.props.resetprogressbar) && (this.props.resetprogressbar === true)) {
                this.resetProgressBar();
            }
            this.fireEvent(this.CALLBACKS.UPLOADTIMEOUT, {
                tfile : file,
                startByte : startByte,
                endByte : endByte,
                retryAttempt : this.monitor.retryAttempt
            });
        }

    }

    // Called on multipart POST request abort
    uploadCancelled(e, file, xhr, startByte, endByte) {
        this.monitor.transferCancelled = true;
        this.fireEvent(this.CALLBACKS.UPLOADCANCELLED, {
            tfile : file,
            startByte : startByte,
            endByte : endByte,
            retryAttempt : this.monitor.retryAttempt
        });
        this.updateUIOnCancellation(file);
    }

    // Called on multipart POST request progress
    uploadProgress(e, file, xhr, startByte, endByte) {
        var progressInfo = this.getUIElement("jfu-progressinfo");
        var progressBar = this.getUIElement("jfu-progressbar");
        if (e.lengthComputable) {
            // It's size of full multipart request, not file content only! but no matter.
            var totalSize = (Number(e.total) + Number(startByte) + Number(file.size - endByte));
            var loadedSize = (Number(e.loaded) + Number(startByte));
            var percent = (loadedSize / totalSize) * 100;
            progressBar.value = Math.round(percent);
            progressBar.textContent = progressBar.value; // Fallback for unsupported browsers.
            if (percent >= 100) {
                progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.completing"].format(file.name, percent.toFixed(1), this.bytesToSize(totalSize));
                this.enableSpinner(true);
                this.fireEvent(this.CALLBACKS.UPLOADCOMPLETING, {
                    tfile : file,
                    startByte : startByte,
                    endByte : endByte,
                    retryAttempt : this.monitor.retryAttempt
                });
            }
            else {
                progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.progress"].format(file.name, percent.toFixed(1), this.bytesToSize(loadedSize), this.bytesToSize(totalSize));
            }
        }
    }

    // Called when all files have been uploaded
    uploadDone(transferredFiles) {
        var progressInfo = this.getUIElement("jfu-progressinfo");
        progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.done"];
        if (this.isDefined(this.props.resetprogressbar) && (this.props.resetprogressbar === true)) {
            this.resetProgressBar();
        }
        this.resetProgressInfoDetails();
        /*if (templateId == TEMPLATEID_JBU) {
            var emptyDiv = this.getUIElement("jbu-filepanelcontainerempty");
            emptyDiv.style.display = "inherit";
            var amount = transferredFiles.length;
            emptyDiv.innerHTML = this.i18n.resources["containerempty.done.label"].format(amount, amount > 1 ? "s" : "");
        }*/

        this.fireEvent(this.CALLBACKS.TRANSFERDONE, {
            tfiles : transferredFiles
        });
        this.executeAfterTransfer(transferredFiles);
        this.resetJFU();
    }

    executeAfterTransfer(transferredFiles) {
        /*if (this.isDefinedNotEmpty(this.props.post)) {
            var xhr = new XMLHttpRequest();
            xhr.addEventListener("loadstart", function(e) {
                enableSpinner(true);
                $.fireEvent(CALLBACKS.FINALPOSTSTARTED, {
                    tfiles : transferredFiles
                });
            }, false);
            xhr.addEventListener("loadend", function(e) {
                enableSpinner(false);
                $.fireEvent(CALLBACKS.FINALPOSTENDED, {
                    status : xhr.status
                });
            }, false);
            if (isDefinedNotEmpty($.props.postparameters)) {
                logMessage("Posting with " + $.props.postparameters, 1);
                var urlparams = formatParameters($.props.postparameters, transferredFiles);
                // HTTP POST with parameters
                var url = getUniqueURL($.props.post);
                xhr.open("POST", url, true);
                addRegularRequestHeader(xhr);
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.send(urlparams);
            }
            else {
                logMessage("Posting", 1);
                // HTTP POST without parameters
                var url = getUniqueURL($.props.post);
                xhr.open("POST", url, true);
                addRegularRequestHeader(xhr);
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.send();
            }
        }*/
        // Redirect after transfer.
        /*if (this.isDefinedNotEmpty(this.props.forward)) {
            var url = $.props.forward;
            if (isDefinedNotEmpty($.props.forwardparameters)) {
                logMessage("Forwarding with " + $.props.forwardparameters, 1);
                var urlparams = formatParameters($.props.forwardparameters, transferredFiles);
                if (url.indexOf("?") > -1) {
                    url = url + "&" + urlparams;
                }
                else {
                    url = url + "?" + urlparams;
                }
            }
            window.setTimeout(function() {
                if (isDefinedNotEmpty($.props.forwardtarget)) {
                    window.open(url, $.props.forwardtarget);
                }
                else {
                    window.location = url;
                }
            }, 1000);
        }*/
    }

    /* CUSTOM EVENTS to plug any callback (REST API ...) */
    // Callback on HEAD call for resume/overwrite.
    infoStateMonitor(e, file, xhr) {
        // Monitor states: UNSENT = 0, OPENED = 1, HEADERS_RECEIVED = 2,LOADING = 3 (Receiving response body), DONE = 4;
        if (xhr.readyState === xhr.DONE) {
            // Status = 200 (Remote file exists, see 'size' header)
            // Status = 404 (Remote file does not exist)
        }
    }

    // Callback on upload status
    uploadStateMonitor(e, file, xhr, startByte, endByte) {
        // Monitor states: UNSENT = 0, OPENED = 1, HEADERS_RECEIVED = 2,LOADING = 3 (Receiving response body), DONE = 4;
        if (xhr.readyState === xhr.DONE && xhr.status === 200) {
            // Every thing ok, file uploaded, handle response here
        }
    }

    addFilesSync(files) {
        this.monitor.addFileCancelled = false;
        this.resetProgressInfoDetails();
        for (var i = 0; i < files.length; i++) {
            if (this.monitor.addFileCancelled === true) {
                break;
            }
            var fileItem = files[i];
            this.newFileCompleted(fileItem, 0, null);
        }
    }

    removeFileFromQueue(file, recursive, src) {
        var filesRemoved = [];
        var topPathHash = this.fileHashCodePart(file, 2);
        // Remove single file from array and return index
        var removeFileFromMonitor = (f) => {
            var fileIndex = this.monitor.files.indexOf(f);
            if (fileIndex > -1) {
                this.monitor.files.splice(fileIndex, 1);
                this.monitor.totalFilesSize = this.monitor.totalFilesSize - f.size;
            }
            return fileIndex;
        };
        if ((recursive === true) && (topPathHash !== 0)) {
            // Find and remove all files with same top path.
            var files = this.getFilesWithHash("_" + topPathHash, -1);
            for (var i = 0; i < files.length; i++) {
                if (removeFileFromMonitor(files[i]) > -1) {
                    filesRemoved.push(files[i]);
                }
            }
        }
        else {
            if (removeFileFromMonitor(file) > -1) {
                filesRemoved.push(file);
            }
        }

        // Remove from UI
        /*if (((this.i18n.resources["queue.table.structure"] == "flat")) || (topPathHash === 0)) {
            // Remove row matching to file
            var rowLi = this.getUIElement(this.fileHashCode(file));
            if (this.isDefined(rowLi)) {
                var rowsDiv = this.getUIElement("jbu-filepanelitems_list");
                rowsDiv.removeChild(rowLi);
            }
        }
        else {
            // Decrease counter/size until row can be removed
            var matchingRowPattern = "[id$='_" + topPathHash + "']";
            var rowLi = document.querySelectorAll(matchingRowPattern);
            if (this.isDefined(rowLi) && (rowLi.length > 0)) {
                var totalItems = this.updateItemRow(rowLi[0], file, false);
                if ((totalItems <= 0) || (recursive === true)) {
                    // Remove row matching to last file in top path.
                    var rowsDiv = this.getUIElement("jbu-filepanelitems_list");
                    rowsDiv.removeChild(rowLi[0]);
                }
            }
        }

        this.updateHeadersLayout();*/
        // JS API.
        for (var j = 0; j < filesRemoved.length; j++) {
            if (this.isDefined(filesRemoved[j])) {
                this.fireEvent(this.CALLBACKS.FILEREMOVEDFROMQUEUE, {
                    tfile : filesRemoved[j],
                    source: src
                });
            }
        }

    }

    // Upload file one by one until all completed
    continueQueuedUpload() {
        if (this.isDefined(this.monitor.files) && (this.monitor.files.length > 0)) {
            this.monitor.transferInProgress = true;
            if ((this.props.resume === true) || this.isDefinedNotEmpty(this.props.overwritepolicy)) {
                this.resumeAndUploadFile(this.monitor.files[0]);
            }
            else {
                this.uploadFile(this.monitor.files[0], -1, -1);
            }
        }
        else {
            this.monitor.transferInProgress = false;
            // No more file to transfer
            this.lockButtonsPanel(true);
            this.enableSpinner(false);
            var amount = this.monitor.filesTranferred.length;
            if (amount > 0) {
                // Transfer is done here
                this.uploadDone(this.monitor.filesTranferred);
            }
        }
    }

    retryTransferOnError(file, startByte, endByte) {
        var reportError = true;
        if (this.isDefinedPositive(this.props.retry) && (this.monitor.retryAttempt < this.props.retry) && (this.monitor.transferCancelled === false)) {
            this.monitor.retryAttempt++;
            reportError = false;
            // Wait retry delay before trying again
            this.enableSpinner(true);
            setTimeout(() => {
                this.enableSpinner(false);
                this.logMessage("Attempt " + this.monitor.retryAttempt + "/" + this.props.retry + " for " + file.name, 1);
                this.uploadFile(file, startByte, endByte);
            }, this.props.retrydelay * 1000);
        }
        return reportError;
    }

    getUniqueURL(url) {
        var uniqueURL = url + ((/\?/).test(url) ? "&" : "?") + (new Date()).getTime();
        return uniqueURL;
    }

    resetMonitor() {
        this.monitor.transferCancelled = false;
        this.monitor.addFileCancelled = false;
        this.monitor.files = [];
        this.monitor.filesTranferred = [];
        this.monitor.totalFilesSize = 0;
        this.monitor.retryAttempt = 0;
        this.monitor.transferInProgress = false;
        this.monitor.uploadId = "";
        this.monitor.partETags = [];
    }

    resetUI() {
        var emptyDiv = this.getUIElement("jbu-filepanelcontainerempty");
        emptyDiv.innerHTML = this.i18n.resources["containerempty.welcome.label"];
        emptyDiv.style.display = "inherit";
        this.updateProgressInfo();
        this.resetProgressBar();
    }

    updateProgressInfo() {
        var progressInfo = this.getUIElement("jfu-progressinfo");
        var amount = this.monitor.files.length;
        if (amount > 0) {
            progressInfo.innerHTML = this.i18n.resources["progresspanel.selection.label"].format(amount, amount > 1 ? "s" : "", this.bytesToSize(this.monitor.totalFilesSize));
        }
        else {
            progressInfo.innerHTML = this.i18n.resources["progresspanel.empty.label"];
            this.resetProgressInfoDetails();
        }
    }

    updateUIOnCancellation(file) {
        this.lockButtonsPanel(true);
        this.enableSpinner(false);
        var progressInfo = this.getUIElement("jfu-progressinfo");
        progressInfo.innerHTML = this.i18n.resources["taskoutput.upload.cancelled"].format(file.name);
        if (this.isDefined(this.props.resetprogressbar) && (this.props.resetprogressbar === true)) {
            this.resetProgressBar();
        }
        this.monitor.transferInProgress = false;
        this.fireEvent(this.CALLBACKS.TRANSFERABORTED, {
            tfiles : this.monitor.filesTranferred
        });
    }

    lockFilePanel(enablePanel) {
        if (this.isDefined(this.getUIElement("jbu-filepanelitems_list"))) {
            var rowsRemove = this.getUIElement("jbu-filepanelitems_list").getElementsByTagName("a");
            if (rowsRemove) {
                for (var i = 0; i < rowsRemove.length; i++) {
                    var aLink = rowsRemove[i];
                    aLink.disabled = !enablePanel;
                }
            }
            var removeAllLink = this.getUIElement("jbu-filepanelheader_remove_link");
            if (removeAllLink) {
                removeAllLink.disabled = !enablePanel;
            }
        }
    }

    lockButtonsPanel(enableStart) {
        var startTransferButton = this.getUIElement("jfu-starttransfer");
        var cancelTransferButton = this.getUIElement("jfu-canceltransfer");
        var selectFilesButton = this.getUIElement("jfu-selectfiles");
        var selectFoldersButton = this.getUIElement("jfu-selectfolders");
        startTransferButton.disabled = !enableStart;
        selectFilesButton.disabled = !enableStart;
        selectFoldersButton.disabled = !enableStart;
        cancelTransferButton.disabled = enableStart;
        this.lockFilePanel(enableStart);
    }

    enableSpinner(enable) {
        var spinnerPanel = this.getUIElement("jfu-spinnerpanel");
        if (enable === true) {
            spinnerPanel.style.display = "inline-block";
        }
        else {
            spinnerPanel.style.display = "none";
        }
    }

    resetProgressBar() {
        var progressBar = this.getUIElement("jfu-progressbar");
        progressBar.value = 0;
        progressBar.textContent = progressBar.value;
    }

    resetProgressInfoDetails() {
        var progressInfoDetails = this.getUIElement("jfu-progressinfodetails");
        progressInfoDetails.innerHTML = this.i18n.resources["progresspanel.details.reset.label"];
    }

    resetJFU() {
        this.resetMonitor();
        var uploadfilesButton = this.getUIElement("jfu-fileinput");
        uploadfilesButton.value = '';
        var uploadfoldersButton = this.getUIElement("jfu-folderinput");
        uploadfoldersButton.value = '';
        if (this.isDefinedNotEmpty(this.props.acceptfilter)) {
            uploadfilesButton.accept = this.props.acceptfilter;
            uploadfoldersButton.accept = this.props.acceptfilter;
        }
        // One or multiple selection
        if (this.isDefinedPositive(this.props.maxfiles)) {
            if (this.props.maxfiles > 1) {
                uploadfilesButton.multiple = "multiple";
                uploadfoldersButton.multiple = "multiple";
            }
            else {
                uploadfilesButton.multiple = "";
                uploadfoldersButton.multiple = "";
            }
        }
    }

  handleSubmit = async event => {
    event.preventDefault();
  }

  async componentDidMount() {
    // log.info(TAG, "componentDidMount", this);

    // Initialize UI resources
    // this.initUI(templateId);
    this.resetJFU();
    this.resetUI();
    this.lockButtonsPanel(true);
    this.enableSpinner(false);

    if (this.isDefined(this.getUIElement("jbu-filepanelheader_filename"))){
        // JBU headers
        this.getUIElement("jbu-filepanelheader_filename").innerHTML = this.i18n.resources["queue.table.column.filename.title"];
        this.getUIElement("jbu-filepanelheader_size").innerHTML = this.i18n.resources["queue.table.column.size.title"];
        this.getUIElement("jbu-filepanelheader_date").innerHTML = this.i18n.resources["queue.table.column.date.title"];
        var removeAllLink = this.getUIElement("jbu-filepanelheader_remove_link");
        removeAllLink.innerHTML = this.i18n.resources["queue.table.column.remove.link"];
        removeAllLink.title = this.i18n.resources["queue.table.column.remove.title"];
        removeAllLink.href = "#";
        removeAllLink.onclick = function() {
            if (!removeAllLink.disabled) {
                this.removeAllFilesFromQueue();
                return false;
            }
        };
    }

    // DnD support
    var filepanel = this.getUIElement("jbu-filepanelcontainer");
    filepanel.ondragover = function() {
        this.className = 'jbufilepanelcontainer jbufilepanelcontainer_hover';
        return false;
    };
    filepanel.ondragleave = function() {
        this.className = 'jbufilepanelcontainer';
        return false;
    };
    filepanel.ondragend = function() {
        this.className = 'jbufilepanelcontainer';
        return false;
    };
    filepanel.ondrop = (e) => {
        this.className = 'jbufilepanelcontainer';
        e.preventDefault();
        this.monitor.addFileCancelled = false;
        this.resetProgressInfoDetails();
        // https://wiki.whatwg.org/wiki/DragAndDropEntries
        var DndEntriesSupported = this.isDefined(e.dataTransfer.items);
        if (DndEntriesSupported === true) {
            // Chrome
            this.readFSEntriesAsync(e.dataTransfer.items);
        }
        else {
            var items = e.dataTransfer.files;
            for (var i = 0; i < items.length; i++) {
                var file = items[i];
                // Prevent browser (FF and Safari) to pass unsupported directory on DnD
                this.addFileIfReadable(file, 0, null);
            }
        }
    };

    // File(s) selection through FileInput hidden button
    var uploadfilesButton = this.getUIElement("jfu-fileinput");
    uploadfilesButton.addEventListener('change', () => {
        this.addFilesSync(this.fileInput.current.files);
    }, false);
    // Select Files button redirects to FileInput button
    var selectfilesButton = this.getUIElement("jfu-selectfiles");
    selectfilesButton.value = this.i18n.resources["progresspanel.button.select.files"];
    selectfilesButton.addEventListener('click', function() {
        uploadfilesButton.click();
    }, false);

    // Folder(s) selection through FileInput hidden button with webkitdirectory
    var uploadfoldersButton = this.getUIElement("jfu-folderinput");
    if (this.isInputFolderSupported(uploadfoldersButton) === true) {
        uploadfoldersButton.addEventListener('change', () => {
            // We get all files recursively with relative path
            this.addFilesSync(this.folderInput.current.files);
        }, false);
        var selectfoldersButton = this.getUIElement("jfu-selectfolders");
        selectfoldersButton.value = this.i18n.resources["progresspanel.button.select.folder"];
        selectfoldersButton.addEventListener('click', function() {
            uploadfoldersButton.click();
        }, false);
        selectfoldersButton.style.display = "none";
    }

    // Start transfer
    var startTransferButton = this.getUIElement("jfu-starttransfer");
    startTransferButton.value = this.i18n.resources["progresspanel.button.start"];
    startTransferButton.addEventListener('click', () => {
        this.monitor.transferCancelled = false;
        this.monitor.retryAttempt = 0;
        this.resetProgressInfoDetails();
        this.continueQueuedUpload();
    }, false);
    startTransferButton.style.display = "none";

    // Cancel transfer
    var cancelTransferButton = this.getUIElement("jfu-canceltransfer");
    cancelTransferButton.value = this.i18n.resources["progresspanel.button.cancel"];

    this.fireEvent(this.CALLBACKS.JSINITIALIZED, {
        version : "1.5",
    });

    // Register to uploader callbacks to update UI.
    for (var l = 0; l < this.JSAPI.length; l++) {
        this.addEventListener(this.JSAPI[l], this.flatUICallback);
    }

  }

    // Display messages in upload panel.
    flatUICallback = (source, event) => {
       var flatUI = this.getUIElement("jbu-filepanelcontainer");
       var line = "";
       if (event.type === this.CALLBACKS.NEWFILEACCEPTED) {
            var emptyDiv = this.getUIElement("jbu-filepanelcontainerempty");
            emptyDiv.style.display = "none";
            if (this.monitor.transferInProgress === false) {
                // Start upload automatically on first file.
                this.monitor.transferCancelled = false;
                this.monitor.retryAttempt = 0;
                this.resetProgressInfoDetails();
                this.continueQueuedUpload();
            }
       }
       else if (event.type === this.CALLBACKS.INFOSTARTED) {
            line = this.i18n.resources["taskoutput.upload.info.connecting"];
       }
       else if (event.type === this.CALLBACKS.UPLOADSTARTED) {
            var file = event.detail.tfile;
            if ((event.detail.startByte === 0) && (event.detail.retryAttempt === 0)) {
                line = this.i18n.resources["taskoutput.upload.info.started"].format(file.name, this.bytesToSize(file.size));
            }
       }
       else if (event.type === this.CALLBACKS.UPLOADCOMPLETED) {
            if (event.detail.full === true) {
                line = this.i18n.resources["taskoutput.upload.info.completed"];
            }
       }
       else if ((event.type === this.CALLBACKS.UPLOADFAILED) || (event.type === this.CALLBACKS.UPLOADTIMEOUT)) {
            line = this.i18n.resources["taskoutput.upload.info.failed"];
       }
       else if (event.type === this.CALLBACKS.TRANSFERABORTED) {
            line = this.i18n.resources["taskoutput.upload.info.cancelled"];
            // Clean list of files to transfer.
            this.resetMonitor();
       }
       else if (event.type === this.CALLBACKS.TRANSFERDONE) {
          var amount = event.detail.tfiles.length;
          line = this.i18n.resources["taskoutput.upload.info.done"].format(amount, amount > 1 ? "s" : "");
          if (this.props.onUploaderTransferDone) {
              this.props.onUploaderTransferDone()
          }
       }
       if (this.isDefinedNotEmpty(line)) {
            var msgDiv = document.createElement("div");
            msgDiv.innerHTML = line;
            flatUI.appendChild(msgDiv);
            flatUI.scrollTop = flatUI.scrollHeight;
       }
    }

  render() {
    return (
        <Form onSubmit={this.handleSubmit}>
             <div id="jfu-mainpanel" className="jfumainpanel">

              <div id="jfu-modalcontainer" className="jfumodalcontainer">
               <div id="jfu-modaldialog">
                   <a id="jfu-modalcontainer-close" className="jfuclose"></a>
                    <div id="jfu-modaldialog-header">
                    </div>
                    <div id="jfu-modaldialog-body">
                    </div>
                    <div id="jfu-modaldialog-footer">
                    </div>
               </div>
              </div>

              <div id="jbu-filepanelcontainer" className="jbufilepanelcontainer">
                <div id="jbu-filepanelcontainerempty" className="jbufilepanelempty"></div>
              </div>

              <div id="jfu-progresspanel" className="jfuprogresspanel">
               <progress id="jfu-progressbar" className="jfuprogressbar" min="0" max="100" value="0"></progress>
               <p id="jfu-progressinfo" className="jfuprogressinfo"></p>
               <p id="jfu-progressinfodetails" className="jfuprogressinfo"></p>
              </div>

              <div id="jfu-buttonpanel" className="jfubuttonpanel">
               <table id="jfu-buttonpaneltable" className="jfubuttonpaneltable">
                <tbody>
                <tr>
                 <td>
                   <input type="file" id="jfu-fileinput" className="jfubutton_fileinput" multiple="multiple" ref={this.fileInput}/>
                   <input type="file" id="jfu-folderinput" className="jfubutton_fileinput" multiple="multiple" webkitdirectory="true" ref={this.folderInput} />
                   <input type="button" id="jfu-selectfolders" className="jfubutton jfubutton_selectfolders" />
                 </td>
                 <td>
                  <div id="jfu-spinnerpanel" className="jfuspinner">
                   <div className="bounce1"></div>
                   <div className="bounce2"></div>
                   <div className="bounce3"></div>
                  </div>
                 </td>
                 <td>
                   <input type="button" id="jfu-starttransfer" className="jfubutton jfubutton_start" />
                   <input type="button" id="jfu-selectfiles" className="jfubutton jfubutton_selectfiles" />
                 </td>
                 <td>
                   <input type="button" id="jfu-canceltransfer"className="jfubutton jfubutton_cancel" />
                 </td>
                </tr>
                </tbody>
               </table>
              </div>
             </div>
        </Form>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    browserContent: state.browserContent,
  }
}

const mapDispatchToProps = { updateBrowserCWD }

const connectedUploader = connect(mapStateToProps, mapDispatchToProps)(Uploader);
export default connectedUploader