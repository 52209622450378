import React, { Component } from "react";
import sizeMe from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import { Image, Row, Col, Container, Modal, Button } from "react-bootstrap";
import { Link} from "react-router-dom";
import log from "../logger"
import * as PluginHelper from "../PluginHelper";
import "./Home.css";
import LoaderSpinner from "../components/LoaderSpinner";
import { connect } from 'react-redux';
import { updateActivePlugin, updateInstalledPlugins } from '../actions/actionCreators';
import { withAlert } from 'react-alert';
import { formatAPIAlert } from '../components/AlertTemplate';

const TAG = "Home"

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      modalShowUninstall: false,
      plugins: [],
      pluginToUninstall: "",
    };
  }

  handleStopEvent(event) {
    var e = event || window.event;
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    e.cancelBubble = true;
    e.returnValue = false;
  }

  handleButtonPress(plugin, event) {
    this.buttonPressTimer = setTimeout(() => this.promptUninstall(plugin), 1250);
  }

  handleButtonRelease(plugin, event) {
    clearTimeout(this.buttonPressTimer);
  }

  handleContextMenu(plugin, event) {
    this.promptUninstall(plugin);
    this.handleStopEvent(event);
    return false;
  }

  // Display uninstall plugin prompt (only for installed plugin)
  promptUninstall(plugin) {
    if (plugin && plugin.uid && plugin.installed === true) {
        this.setState({ modalShowUninstall: true, pluginToUninstall: plugin});
    }
  }

  async uninstallPlugin(plugin) {
    this.modalCloseUninstall();
    this.setState({ isLoading: true });
    try {
        const ret = await PluginHelper.uninstallPlugin(this.props.account.token, plugin.uid);
        console.log(TAG, "Plugin uninstalled", ret);
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
    }
    await this.loadHome();
  }

  modalCloseUninstall() {
    this.setState({ modalShowUninstall: false, pluginToUninstall: "" });
  }

  async loadHome() {
    this.setState({ isLoading: true });
    try {
      const plugins = await PluginHelper.loadPluginsHome(this.props.account.token);
      this.setState({ plugins });
      // Push installed plugins to Redux store (needed by Messages component)
      this.props.updateInstalledPlugins(plugins);
      this.props.updateActivePlugin({name:""});
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    // Disable right click on document.
    document.addEventListener('contextmenu', function(event){
        if (event.defaultPrevented) return;
        event.preventDefault();
    });
    await this.loadHome();
    log.info(TAG, "componentDidMount", this);
  }

  renderPluginsList(plugins) {
    const {
      size: {
        width
      }
    } = this.props;

    if (plugins && plugins.length > 0) {
        return (
          <StackGrid columnWidth={width <= 640 ? width <= 480 ? width <= 320 ? '100%' : '50%' : '33.3%' : '25%'}>
          {plugins.map((plugin) => (
            this.renderItem(plugin)
           ))}
          </StackGrid>
        );
    }
    else {
        return (
         <div>No plugin available</div>
        )
    }
  }

  renderOverlayIcon(plugin) {
    if (plugin.overlayIcon) {
      return (
        <Image className="install-icon" src={plugin.overlayIcon}/>
      )
    }
  }

  renderAdminIcon(plugin) {
      if (plugin.adminIcon) {
        return (
          <Image className="admin-icon" src={plugin.adminIcon}/>
        )
      }
  }

  renderItem(plugin) {
    let action = "/browse/"
    if (!plugin.installed) {
        action = "/install/"
    }
    return (
        <Container fluid style={{height: '116px', overflow: 'hidden'}} key={plugin.uid}>
          <Row>
            <Col>
              <Link to={ {pathname: action, state: {plugin: plugin}} }>
                <div className="icon-top-container"
                     onContextMenu={(e) => this.handleContextMenu(plugin, e)}
                     onTouchStart={(e) => this.handleButtonPress(plugin, e)}
                     onTouchEnd={(e) => this.handleButtonRelease(plugin, e)}
                     onMouseDown={(e) => this.handleButtonPress(plugin, e)}
                     onMouseUp={(e) => this.handleButtonRelease(plugin, e)}
                     onMouseLeave={(e) => this.handleButtonRelease(plugin, e)}>
                  <div className="icon-container">
                    {this.renderAdminIcon(plugin)}
                    {this.renderOverlayIcon(plugin)}
                    <Image className="center-image" src={plugin.iconUrl} width='72' height="72"/>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
          <Row style={{fontSize: '90%', fontWeight: 'bold'}}>
            <Col className="text-center">{plugin.name}</Col>
          </Row>
        </Container>
    );
  }

  /*renderTitle() {
    return (
        <div key="homeTitle">
          <p className="title">RepFiles<br/>Efficient. Prepared. Mobile.</p>
        </div>
    );
  }*/

  renderPlugins() {
    return (
      <div className="plugins">

        <Modal show={this.state.modalShowUninstall} onHide={() => this.modalCloseUninstall()} backdrop="static">
          <Modal.Header closeButton>
             <Modal.Title>Remove Plugin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Uninstall {this.state.pluginToUninstall.name} Plugin?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.modalCloseUninstall()}>Cancel</Button>
            <Button variant="success" onClick={() => this.uninstallPlugin(this.state.pluginToUninstall)}>Proceed</Button>
          </Modal.Footer>
        </Modal>

        {!this.state.isLoading && this.renderPluginsList(this.state.plugins)}
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {!this.state.isLoading
            ? this.renderPlugins()
            : ([<LoaderSpinner loadingText="Loading ..." key="loadingTitle"/>])
        }
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    account: state.account,
    activePlugin: state.activePlugin,
    installedPlugins: state.installedPlugins,
  }
}
const mapDispatchToProps = { updateActivePlugin , updateInstalledPlugins }
const connectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);
const sizedConnectedHome = sizeMe()(connectedHome)
export default withAlert()(sizedConnectedHome);
