import * as loglevel from 'loglevel'

// window.LOG_LEVEL = 'DEBUG';

const PROD = false
if (!PROD) {
  loglevel.setLevel('DEBUG')
} else {
  loglevel.setLevel('ERROR')
}

export default loglevel