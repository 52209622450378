import { API } from "aws-amplify";
//import log from "./logger"

//const TAG = "AccountHelper"
export const NEMRA_EDITION = {name: "NEMRA Edition", fullname: "RepFiles NEMRA Edition", category : "NEMRA", iconUrl : "./images/nemra72.png"}
export const NAED_EDITION = {name: "NAED Edition", fullname: "RepFiles NAED Edition", category : "NAED", iconUrl : "./images/naed72.png"}
export const LIGHTING_EDITION = {name: "Lighting Edition", fullname: "RepFiles Lighting Edition", category : "LIGHTING", iconUrl : "./images/lighting72.png"}
export const GENERIC_EDITION = {name: "Generic Edition", fullname: "RepFiles Generic Edition", category : "REPFILES", iconUrl : "./images/generic72.png"}

export const INITIAL_STATE = {
    account: {login: "", token:"", isAuthenticated: false, application: NEMRA_EDITION},
    activePlugin: {name: ""},
    installedPlugins: [],
    selection: [],
    pickFolderSelection: {},
    browserContent: {"folder": {}, "items": []},
    browserCWD : {},
    clipboard: {"cut": false, "items": []},
    version: "1.2.1"
}

// Call AccountService from API
function loadAccountAPI(account) {
    let payload = {
        body: account,
    }
    return API.post("account", "", payload);
}

// Authenticate user and return token if succeeded
export async function signIn(account) {
    const payload = await loadAccountAPI(account);
    return payload;
}

