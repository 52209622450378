import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from 'react-redux'
import { updateAccount } from '../actions/actionCreators'
import { withAlert } from 'react-alert'
import { formatAPIAlert } from '../components/AlertTemplate'
import LoaderButton from "../components/LoaderButton";
import * as AccountHelper from "../AccountHelper";
import log from "../logger"
import "./Login.css";

const TAG = "Login"


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });

  }

  handleEditionChange = event => {
    if (event.target.value === "NEMRA") {
        this.props.updateAccount({login: this.props.account.login, token: this.props.account.token, isAuthenticated: this.props.account.isAuthenticated, application: AccountHelper.NEMRA_EDITION});
    }
    else if (event.target.value === "NAED") {
        this.props.updateAccount({login: this.props.account.login, token: this.props.account.token, isAuthenticated: this.props.account.isAuthenticated, application: AccountHelper.NAED_EDITION});
    }
    else if (event.target.value === "LIGHTING") {
        this.props.updateAccount({login: this.props.account.login, token: this.props.account.token, isAuthenticated: this.props.account.isAuthenticated, application: AccountHelper.LIGHTING_EDITION});
    }
    else if (event.target.value === "REPFILES") {
        this.props.updateAccount({login: this.props.account.login, token: this.props.account.token, isAuthenticated: this.props.account.isAuthenticated, application: AccountHelper.GENERIC_EDITION});
    }
  }

  // Sign in with login/password and update account in redux with returned token
  handleSubmit = async event => {
    event.preventDefault();

      this.setState({ isLoading: true });

      try {
        const payload = await AccountHelper.signIn({"login": this.state.email, "password": this.state.password, "application": this.props.account.application.category});
        if (payload.token) {
            this.props.updateAccount({login: this.state.email, token: payload.token, isAuthenticated: true, application: this.props.account.application});
        }
        else {
            throw new Error("Authentication failed, missing token");
        }
        this.setState({ isLoading: false });
        // Back to home
        this.props.history.push("/");
      } catch (e) {
        this.setState({ isLoading: false });
        this.props.alert.error(formatAPIAlert("Login failed"));
        log.info(TAG, e);
      }
  }

  async componentDidMount() {
    log.info(TAG, "RepFiles " + this.props.account.application.category + " " + this.props.version)
    log.info(TAG, "componentDidMount", this);
  }

  render() {
    return (
      <div className="Login">
        <div className="register">
           Sign-in with your RepFiles account information<br/> or <a href="https://www.repfiles.net/Home/Registration.aspx" target="_blank" rel="noopener noreferrer">click here to register.</a>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="application">
            <Form.Label>Select an Edition</Form.Label>
            <Form.Control as="select" onChange={this.handleEditionChange} value={this.props.account.application.category}>
              <option value="NEMRA">NEMRA Edition</option>
              <option value="NAED">NAED Edition</option>
              <option value="LIGHTING">Lighting Edition</option>
              <option value="REPFILES">Generic Edition</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="email">
            Email:
            <Form.Control
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          Password:
          <Form.Group controlId="password">
            <Form.Control
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
            <LoaderButton
              block
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Login"
              loadingText=" Logging in..."
              variant="success"
            />
          <div className="reset_password">
            <a href="https://www.repfiles.net/Home/AccountLogin/tabid/199/ctl/SendPassword/Default.aspx?returnurl=%2f" target="_blank" rel="noopener noreferrer">Reset password</a>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    version: state.version
  }
}

const mapDispatchToProps = { updateAccount }
const connectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withAlert()(connectedLogin);
