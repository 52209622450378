import * as AccountHelper from '../AccountHelper'

export default (state, action) => {
  switch (action.type) {
    case "UPDATE_ACTIVEPLUGIN":
      return Object.assign({}, state, {
        activePlugin: action.plugin
      })
    case "UPDATE_INSTALLEDPLUGINS":
      return Object.assign({}, state, {
        installedPlugins: action.plugins
      })
    case "UPDATE_ACCOUNT":
      return Object.assign({}, state, {
        account: action.account
      })
    case "UPDATE_SELECTION":
      return Object.assign({}, state, {
        selection: action.selection
      })
    case "UPDATE_PICK_FOLDER_SELECTION":
      return Object.assign({}, state, {
        pickFolderSelection: action.pickFolderSelection
      })
    case "UPDATE_BROWSER_CONTENT":
      return Object.assign({}, state, {
        browserContent: action.browserContent
      })
    case "UPDATE_BROWSER_CWD":
      return Object.assign({}, state, {
        browserCWD: action.browserCWD
      })
    case "UPDATE_CLIPBOARD":
      return Object.assign({}, state, {
        clipboard: action.clipboard
      })
    case "RESET_APPLICATION":
      return Object.assign({}, state,
        AccountHelper.INITIAL_STATE)
    default:
      return state;
  }
};