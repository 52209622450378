import React, { Component } from "react";
import sizeMe from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import { Image, Row, Col, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { updateSelection, updatePickFolderSelection, updateBrowserContent } from '../actions/actionCreators'
import LoaderSpinner from "../components/LoaderSpinner";
import { connect } from 'react-redux'
import { withAlert } from 'react-alert'
import { formatAPIAlert } from '../components/AlertTemplate'
import * as FSHelper from "../FSHelper";
import log from "../logger";
import config from "../config";
import "./Browser.css";

const TAG = "Browser"

class Browser extends Component {
  constructor(props) {
    super(props);

    let initialFolderPath = "/";
    if (this.props.location && this.props.location.state && this.props.location.state.initialFolder) {
        initialFolderPath = this.props.location.state.initialFolder.absolutePath;
    }

    let currentPlugin = (this.props.location && this.props.location.state && this.props.location.state.plugin) ? this.props.location.state.plugin : this.props.activePlugin
    let currentPluginUid = (this.props.overridePlugin) ? this.props.overridePlugin.uid : currentPlugin.uid

    const initialFolder = {
        "type": 1,
        "name": "",
        "absolutePath": initialFolderPath,
        "ext" : currentPluginUid
    }

    this.state = {
      isLoading: true,
      currentFolder: initialFolder,
      files: [],
      folderProperties : {},
      itemSelector: this.props.itemSelector ? this.props.itemSelector : 'checkbox',
      typeFilter: this.props.typeFilter,
      dropBackTitle: this.props.dropBackTitle,
      overridePlugin: this.props.overridePlugin,
    };

    if (this.props.updateActivePlugin) {
        this.props.updateActivePlugin(currentPlugin)
        // Clipboard scope is limited to current plugin.
        this.props.updateClipboard({"cut": false, "items" : []});
    }

    this.changeCurrentFolder = this.changeCurrentFolder.bind(this);
  }

   // Reload content
  changeCurrentFolder = (newFolder) => {
    log.info(TAG, "changeCurrentFolder", newFolder, this);
    this.setState({
      currentFolder: newFolder
    });
  };

  async componentDidMount() {
    await this.loadItems(this.state.currentFolder);
    log.info(TAG, "componentDidMount", this);
  }

  componentWillUnmount() {
    this.props.updateBrowserContent({"folder": {}, "items": []});
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.browserCWD.update && nextProps.browserCWD.update === true) {
        this.loadItems(this.state.currentFolder);
        this.props.updateBrowserCWD({})
    }
    if ((nextProps.location && nextProps.location.state && nextProps.location.state.action && nextProps.location.state.action === 'reload') && ((!this.props.location.state) || (this.props.location.state.plugin && this.props.location.state.plugin.uid && nextProps.location.state.plugin.uid !== this.props.location.state.plugin.uid))) {
      log.info(TAG, "componentWillReceiveProps reloading", nextProps.location.state.plugin.uid);
      if (nextProps.location.state.plugin.uid === config.conf.MYFILES_PLUGIN.uid) {
        this.props.updateActivePlugin(config.conf.MYFILES_PLUGIN);
        await this.loadItems({"type": 1, "name": "", "absolutePath": "/", "ext" : nextProps.location.state.plugin.uid});
      }
    }
  }

  async loadItems(folder) {
    const start = new Date().getTime();
    this.setState({ isLoading: true });
    try {
      const items = await FSHelper.loadFiles(this.props.account.token, folder);
      for (let i in items.files) {
        items.files[i].selected = false;
        for (let j in this.props.selection) {
            if (this.props.selection[j].absolutePath === items.files[i].absolutePath) {
                items.files[i].selected = true;
                break;
            }
        }
      }
      this.setState({ files: items.files, currentFolder: folder, folderProperties: items.folderProperties});
      this.props.updateBrowserContent({"folder": folder, "items": this.state.files});
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
    }
    this.setState({ isLoading: false });
    const duration = new Date().getTime() - start;
    log.info(TAG, "loadItems completed in " + duration, folder);
  }

  async openItem(item) {
    if (FSHelper.isDefined(item)) {
        if (item.type === 1) {
            await this.loadItems(item);
        }
        else if (item.type === 0) {

        }
    }
  }

  renderFilesList(files) {
    const {
      size: {
        width
      }
    } = this.props;

    let filteredFiles = files
    if (this.state.typeFilter && this.state.typeFilter === "folder") {
        filteredFiles = []
        for (let i=0; i<files.length; i++) {
            if (files[i].type === 1) {
                filteredFiles.push(files[i])
            }
        }
    }

    return (
      <StackGrid columnWidth={width <= 640 ? width <= 480 ? width <= 320 ? '100%' : '50%' : '33.3%' : '25%'} key="grid">
      {filteredFiles.map((file) => (
        this.renderItem(file)
       ))}
      </StackGrid>
    )
  }

  // Add or remove item from selection
  toggleItemSelection(file) {
    let newSelection = []
    let found = false;
    for (let i=0; i < this.props.selection.length; i++) {
        if (file.absolutePath === this.props.selection[i].absolutePath) {
            found = true;
            file.selected = false;
        }
        else {
            newSelection.push(this.props.selection[i]);
        }
    }
    if (found === false) {
        file.selected = true;
        newSelection.push(file);
    }
    this.props.updateSelection(newSelection);
  }

  // Pick folder
  pickFolder(item) {
    if (item && item.type === 1) {
        this.props.updatePickFolderSelection(item);
    }
  }

  // Clean Pick folder and open item
  cleanPickFolderAndOpenItem(item) {
     this.props.updatePickFolderSelection({});
     this.openItem({type: 1, absolutePath: '/', ext: item.ext, name: ''});
  }

  renderSelectionBox(file) {
    if (this.state.itemSelector && this.state.overridePlugin) {
        return (
            <Form.Check custom defaultChecked={file.selected} type={this.state.itemSelector} id={file.absolutePath} onChange={() => this.pickFolder(file)} name="itemBox" label="" className="selection-box"/>
        )
    }
    else {
        return (
            <Form.Check custom defaultChecked={file.selected} type={this.state.itemSelector} id={file.absolutePath} onChange={() => this.toggleItemSelection(file)} name="itemBox" label="" className="selection-box"/>
        )
    }
  }

  renderLink(file) {
    if (file.type === 0) {
        return (
          <Link to={{pathname: '/view/', state: { file: file}}}>
            <Image className="grid-center-image" src={file.previewPresignedUrl !== null ? file.previewPresignedUrl : file.iconUrl}/>
          </Link>
        )
    }
    else {
        return (
          <Link to={{pathname: "/browse/", state: {plugin: this.props.activePlugin}}} onClick={() => this.openItem(file)}>
            <Image className="grid-center-image" src={file.iconUrl}/>
          </Link>
        )
    }
  }

  renderGridIcon(file) {
    return (
        <div className="grid-icon-top-container">
          <div className="grid-icon-container">
            {this.renderSelectionBox(file)}
            {this.renderLink(file)}
          </div>
        </div>
    )
  }

  renderItem(file) {
    return (
        <Container fluid style={{height: '110px', overflow: 'hidden'}} key={file.absolutePath}>
          <Row>
            <Col>
               {this.renderGridIcon(file)}
            </Col>
          </Row>
          <Row style={{fontSize: '90%', fontWeight: 'plain'}}>
            <Col className="text-center">{file.name}</Col>
          </Row>
        </Container>
    );
  }

  renderFolderLink(parentItem, subfolder, idx, total) {
    if (subfolder.length > 0) {
        if ((idx > 0) && (idx < total - 1)) {
            return (
                <span key={subfolder+"_"+idx}>&nbsp;/&nbsp;<Link to='/browse/' onClick={() => this.openItem(parentItem)}>{subfolder}</Link></span>
            )
        }
        else {
            return (
                <span key={subfolder+"_"+idx}>&nbsp;/&nbsp;{subfolder}</span>
            )
        }
    }
  }

  renderBackPluginTitle(item) {
    if (this.state.dropBackTitle) {
        return (
            <span>
                <Link to='/browse/' onClick={() => this.cleanPickFolderAndOpenItem(item)}>{this.state.overridePlugin ? this.state.overridePlugin.name : this.props.activePlugin.name}</Link>
            </span>
        )
    }
    else {
        return (
            <span>
                <Link to="/" onClick={() => this.props.updateActivePlugin("")}>Home</Link>
                &nbsp;/&nbsp;<Link to='/browse/' onClick={() => this.openItem({type: 1, absolutePath: '/', ext: item.ext, name: ''})}>{this.props.activePlugin.name}</Link>
            </span>
        )
    }
  }

  // Home / Plugin Name / Folder / Sub-folder
  renderBack(item) {
      const parentItem = FSHelper.getParent(item);
      let path = item.absolutePath;
      if (path.endsWith("/")) path = path.substring(0, (path.length-1));
      const subfolders = path.split("/")
      return (
        <div style={{paddingTop:'8px', paddingLeft:'4px'}}>
            {this.renderBackPluginTitle(item)}
            {FSHelper.isDefined(parentItem.absolutePath) ?
            subfolders.map((subfolder, idx) => (
                this.renderFolderLink(FSHelper.getParentAtLevel(item, subfolders.length - 2 - idx), subfolder, idx, subfolders.length)
            ))
            : ""}
        </div>
      );
  }

  renderTitle(item) {
    let path = item.absolutePath;
    if (path === "/") path = "";
    return (
        <div className="browser-title" key="browser_title">
        <div className="folder-bar table">
          <div className="folder-title cell my-auto">{this.renderBack(item)}</div>
          <div className="folder-title-details cell my-auto">Folder size<br/>{FSHelper.getBytes(this.state.folderProperties.size)}</div>
        </div>
        </div>
    )
  }

  renderFiles() {
    return (
      <div className="files">
      <Form>
        {([this.renderTitle(this.state.currentFolder), this.renderFilesList(this.state.files)])}
      </Form>
      </div>
    );
  }

  render() {
    return (
      <div className="Browser">
        {!this.state.isLoading ?
            this.renderFiles():
            <LoaderSpinner loadingText="Loading ..."/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    activePlugin: state.activePlugin,
    selection: state.selection,
    browserContent: state.browserContent,
    browserCWD: state.browserCWD,
    pickFolderSelection: state.pickFolderSelection,
  }
}

const mapDispatchToProps = { updateSelection, updatePickFolderSelection, updateBrowserContent }

const connectedBrowser = connect(mapStateToProps, mapDispatchToProps)(Browser);
const sizedConnectedBrowser = sizeMe()(connectedBrowser)

export default withAlert()(sizedConnectedBrowser);