import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import LoaderSpinner from "../components/LoaderSpinner";
import * as PluginHelper from "../PluginHelper";
import log from "../logger"
import { withAlert } from 'react-alert'
import { connect } from 'react-redux'
import { formatAPIAlert, formatMessage } from '../components/AlertTemplate'
import { updateActivePlugin } from '../actions/actionCreators'
import parse from 'html-react-parser';
import "./Install.css";

const TAG = "Install"

class Install extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      plugin: {},
      pluginRequestAccess : {},
    };

  }

  async componentDidMount() {
    try {
      // Try to install
      const plugin = await PluginHelper.installPlugin(this.props.account.token, this.props.location.state.plugin.uid);
      if (plugin.description && plugin.description.length > 0) {
        log.info(TAG, "componentDidMount", "Plugin access denied", plugin);
        const deniedPlugin = Object.assign({}, this.props.location.state.plugin);
        deniedPlugin.description = plugin.description;
        this.setState({ isLoading: false , plugin: {}, pluginRequestAccess: deniedPlugin});
      }
      else {
        log.info(TAG, "componentDidMount", "Plugin installed", plugin);
        this.setState({ isLoading: false , plugin: plugin, pluginRequestAccess: {}});
      }
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
      this.setState({ isLoading: false , plugin: {}, pluginRequestAccess: this.props.location.state.plugin});
    }
  }

  async sendRequestAccess(plugin) {
    log.info(TAG, "sendRequestAccess", plugin.uid);
    this.setState({ isLoading: true});
    try {
        const payload = await PluginHelper.requestAccessPlugin(this.props.account.token, plugin.uid);
        if (payload) {
            if (payload.status) {
                if (payload.status === 1) {
                    this.props.alert.success(formatMessage("Request Access sent successfully."))
                }
                else if (payload.status === 2) {
                    this.props.alert.success(formatMessage("Request Access already sent."))
                }
                else {
                    this.props.alert.error(formatMessage("Request Access not sent."))
                }
            }
        }
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
    }
    this.setState({ isLoading: false});
  }

  backToAddNew(plugin) {
    this.props.history.push("/addnew/");
  }

  renderPlugin() {
    if (this.state.plugin && this.state.plugin.uid && this.state.plugin.uid.length > 0) {
        return (
          <div className="plugin">
            <Redirect to={{pathname: '/browse/', state: {plugin: this.state.plugin}}}/>
          </div>
        );
    }
    else {
        if (this.state.pluginRequestAccess && this.state.pluginRequestAccess.uid && this.state.pluginRequestAccess.uid.length > 0) {
            let text = this.state.pluginRequestAccess.description ? this.state.pluginRequestAccess.description : "Plugin access denied"
            return (
              <div className="plugin">
                <div className="request-container">
                  <div className="micon">
                     <Image src={this.state.pluginRequestAccess.iconUrl} width='72' height="72"/>
                  </div>
                  <div className="mcontainer">
                      <div className="mrow-body">
                         <span className="mcolumn ellipsis subject">{this.state.pluginRequestAccess.name}</span>
                         <span className="mcolumn body">{parse(text.replace(/\n/g, '<br />'))}</span>
                      </div>
                      <div className="mrow-buttons">
                        <Button variant="link" onClick={() => this.backToAddNew(this.state.pluginRequestAccess)}>Back</Button>&nbsp;&nbsp;&nbsp;
                        <Button variant="success" onClick={() => this.sendRequestAccess(this.state.pluginRequestAccess)}>Request Access</Button>
                      </div>
                   </div>
                </div>
              </div>
            );
        }
        else {
            return (
                <div className="plugin">
                    Cannot install plugin
                </div>
            )
        }
    }
  }

  render() {
    return (
      <div className="Install">
        {!this.state.isLoading ?
            this.renderPlugin():
            <LoaderSpinner loadingText="Installing Plugin ..."/>
        }
      </div>
    )
  }
}

const mapDispatchToProps = { updateActivePlugin }
const mapStateToProps = (state) => {
  return {
    account: state.account,
  }
}
const connectedInstall = connect(mapStateToProps, mapDispatchToProps)(Install);
export default withAlert()(connectedInstall);