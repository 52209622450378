//const API_URL = 'https://hcc852gclj.execute-api.us-east-1.amazonaws.com/Prod'; // Development
const API_URL = 'https://ldht4m5fqb.execute-api.us-east-1.amazonaws.com/Prod'; // Production
const API_REGION = "us-east-1";

export default {

  apiGatewayAccount: {
    REGION: API_REGION,
    URL: API_URL + "/account/authvalidate"
  },
  apiGatewayPluginList: {
    REGION: API_REGION,
    URL: API_URL + "/plugin/list"
  },
  apiGatewayPluginListInstalled: {
    REGION: API_REGION,
    URL: API_URL + "/plugin/listinstalled"
  },
  apiGatewayPluginInstall: {
    REGION: API_REGION,
    URL: API_URL + "/plugin/install"
  },
  apiGatewayPluginUninstall: {
    REGION: API_REGION,
    URL: API_URL + "/plugin/uninstall"
  },
  apiGatewayPluginRequestAccess: {
    REGION: API_REGION,
    URL: API_URL + "/plugin/ra"
  },
  apiGatewayPluginListPM: {
    REGION: API_REGION,
    URL: API_URL + "/plugin/listpm"
  },
  apiGatewayMessagesList: {
    REGION: API_REGION,
    URL: API_URL + "/messages/list"
  },
  apiGatewayMessagesStatus: {
    REGION: API_REGION,
    URL: API_URL + "/messages/setmsgusr"
  },
  apiGatewayFSList: {
    REGION: API_REGION,
    URL: API_URL + "/fs/list"
  },
  apiGatewayFSDownloadZIP: {
    REGION: API_REGION,
    URL: API_URL + "/download/zip"
  },
  apiGatewayFSDownloadShare: {
    REGION: API_REGION,
    URL: API_URL + "/download/share"
  },
  apiGatewayFSDownloadShareSaveAs: {
    REGION: API_REGION,
    URL: API_URL + "/download/share-download"
  },
  apiGatewayFSDownloadAddToMyFiles: {
    REGION: API_REGION,
    URL: API_URL + "/download/addtomyfiles"
  },
  apiGatewayFSManageMkDir: {
    REGION: API_REGION,
    URL: API_URL + "/fsmanage/mkdir"
  },
  apiGatewayFSManageDelete: {
    REGION: API_REGION,
    URL: API_URL + "/fsmanage/delete"
  },
  apiGatewayFSManageRename: {
    REGION: API_REGION,
    URL: API_URL + "/fsmanage/rename"
  },
  apiGatewayFSManageCopy: {
    REGION: API_REGION,
    URL: API_URL + "/fsmanage/copy"
  },
  apiGatewayFSManageUpload: {
    REGION: API_REGION,
    URL: API_URL + "/fsmanage/upload"
  },
  conf: {
    MYFILES_PLUGIN: {uid: "o1.p2", name: "MyFiles"}
  }
};
