import { API } from "aws-amplify";
import log from "./logger"

export const UNKNOWN = -1;
export const FILE = 0;
export const FOLDER = 1;
export const ROOTFOLDER = 2;
export const UPFOLDER = 3;

const TAG = "FSHelper"

export function isDefined(x) {
    return typeof (x) != 'undefined' && x !== null;
}

export function isDefinedNotEmpty(x) {
    return (isDefined(x) && (x.length > 0));
}

export function getBytes(bytes) {
    if (bytes <= 0) { return "-"; }
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes/Math.pow(1024, e)).toFixed(1)+' '+' KMGTP'.charAt(e)+'B';
}

export function formatDate(lastModified) {
    if (lastModified <= 0) { return "-"; }
    return (new Date(lastModified)).toLocaleDateString()
}

export function getParent(file)
{
    let parent = null;
    if (isDefined(file))
    {
        let home = "/";
        let path = file.absolutePath;
        if (isDefined(path))
        {
            let name = "";
            if ((path === "/") || (path === home)) path = null;
            else
            {
                // Ends with "/" then it's a folder.
                if (path.endsWith("/")) path = path.substring(0, (path.length-1));
                let ind = path.lastIndexOf("/");
                if (ind >= 0)
                {
                    path = path.substring(0, ind);
                    ind = path.lastIndexOf("/");
                    if (ind >= 0) name = path.substring(ind + 1, path.length);
                    if (path === "") path = "/";
                }
            }
            parent = {type: FOLDER, absolutePath: path, name: name, ext: file.ext};
        }
    }
    return parent;
}

export function getParentAtLevel(file, level)
{
    let parent = null;
    if (isDefined(file))
    {
        parent = getParent(file);
        for (let i=0; i<level; i++) {
            parent = getParent(parent);
        }
    }
    return parent;
}

export function getBase(filename)
{
    let base = filename;
    if ((filename) && (filename.lastIndexOf(".") !== -1)) {
        base = filename.substring(0, filename.lastIndexOf("."));
    }
    return base;
}

export function getExtension(filename)
{
    let ext = "";
    if ((filename) && (filename.lastIndexOf(".") !== -1)) {
        ext = filename.substring(filename.lastIndexOf("."));
    }
    return ext;
}



// Remote service that download content
export function downloadFilesZIPAPI(token, items) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: items,
    }
    return API.post("fsdownloadzip", "", payload);
}

export function downloadFilesShare(token, items) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: items,
    }
    return API.post("fsdownloadshare", "", payload);
}

export function downloadFilesShareSaveAs(token, items) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: items,
    }
    return API.post("fsdownloadsharesaveas", "", payload);
}

export function downloadFilesAddToMyFiles(token, items) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: items,
    }
    return API.post("fsdownloadaddtomyfiles", "", payload);
}

export function makeDirectoryAPI(token, item) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: item,
    }
    return API.post("fsmanagemkdir", "", payload);
}

export function deleteAPI(token, items) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: items,
    }
    return API.post("fsmanagedelete", "", payload);
}

export function renameAPI(token, itemFrom, itemTo) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: {"from": itemFrom, "to": itemTo},
    }
    return API.post("fsmanagerename", "", payload);
}

export function copyAPI(token, itemsFrom, itemTo) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: {"fromItems": itemsFrom, "to": itemTo},
    }
    return API.post("fsmanagecopy", "", payload);
}

// Remote service that load folder content
function loadFilesAPI(token, dir) {
    let payload = {
        headers: {"Authorization": "Bearer " + token},
        body: dir,
    }
    return API.post("fslist", "", payload);
}

function loadIcon(file) {
    if (file.type === FOLDER) {
        file.iconUrl = "/fs/Folder.png"
    }
    else if (file.type === FILE) {
        file.iconUrl = "/fs/File.png";
        if (isDefinedNotEmpty(file.mimeType)) {
            if (file.mimeType === "application/pdf") {
                file.iconUrl = "/fs/PDF.png"
            }
            else if (file.mimeType.startsWith("image/")) {
                file.iconUrl = "/fs/Image.png"
            }
            else if (file.mimeType.startsWith("audio/")) {
                file.iconUrl = "/fs/Audio.png"
            }
            else if (file.mimeType.startsWith("video/")) {
                file.iconUrl = "/fs/Video.png"
            }
            else if (file.mimeType === ("application/vnd.ms-powerpoint")) {
                file.iconUrl = "/fs/PPT.png"
            }
            else if (file.mimeType === ("application/vnd.ms-excel")) {
                file.iconUrl = "/fs/Spreadsheet.png"
            }
            else if ((file.mimeType === ("application/msword")) || (file.mimeType === ("application/rtf")) ||
                     (file.mimeType === "text/plain")) {
                file.iconUrl = "/fs/Text_Doc.png"
            }
            else if ((file.mimeType === ("application/zip")) || (file.mimeType === ("application/x-gzip")) ||
                     (file.mimeType === "text/x-rar-compressed")) {
                file.iconUrl = "/fs/Archive.png"
            }
            else {
                file.iconUrl = "/fs/File.png"
            }
        }
    }
}


// Sort files by name.
function compare(a, b) {
    const nameA = a.name;
    const nameB = b.name;
    const defaultCmp = -1;
    let cmp = 0;
    if (a.type === b.type) {
        // Sort by name
        if (nameA > nameB) cmp = 1;
        else if (nameA < nameB) cmp = -1;
        else cmp = 0;
    }
    else {
        if (a.type === ROOTFOLDER)
        {
            if (b.type === UPFOLDER) cmp = -defaultCmp;
            else cmp = -1;
        }
        else if (a.type === UPFOLDER)
        {
            cmp = defaultCmp;
        }
        else if (a.type === FOLDER)
        {
            if (b.type === UPFOLDER) cmp = -defaultCmp;
            else if (b.type === ROOTFOLDER) cmp = 1;
            else cmp = -1;
        }
        else if (a.type === FILE)
        {
            if (b.type === UPFOLDER) cmp = -defaultCmp;
            else if (b.type === UNKNOWN) cmp = -1;
            else cmp = 1;
        }
        else if (a.type === UNKNOWN)
        {
            if (b.type() === UPFOLDER) cmp = -defaultCmp;
            else cmp = 1;
        }
        else
        {
            cmp = 1;
        }
    }
    return cmp;
}

// Load Files for a given folder screen.
export async function loadFiles(token, dir) {
    let files = [];
    let properties = {}
    try {
      const payload = await loadFilesAPI(token, dir);
      const pluginFiles = payload.files;
      properties = payload.properties;

      // Generate icon URL and overlay icons
      for (let i=0; i < pluginFiles.length; i++) {
        const file = pluginFiles[i]
        loadIcon(file);
        files.push(file);
      }

      // Sort plugins by name. MyFiles first.
      files.sort(compare);

    } catch (e) {
      log.error(TAG, "loadFiles", e);
      throw e;
    }
    return {files: files, folderProperties: properties};
}
