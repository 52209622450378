export function updateActivePlugin(plugin) {
  return {
    type: 'UPDATE_ACTIVEPLUGIN',
    plugin
  }
};

export function updateInstalledPlugins(plugins) {
  return {
    type: 'UPDATE_INSTALLEDPLUGINS',
    plugins
  }
};

export function updateAccount(account) {
  return {
    type: 'UPDATE_ACCOUNT',
    account
  }
};

export function updateSelection(selection) {
  return {
    type: 'UPDATE_SELECTION',
    selection
  }
};

export function updatePickFolderSelection(pickFolderSelection) {
  return {
    type: 'UPDATE_PICK_FOLDER_SELECTION',
    pickFolderSelection
  }
};

export function updateBrowserContent(browserContent) {
  return {
    type: 'UPDATE_BROWSER_CONTENT',
    browserContent
  }
};

export function updateBrowserCWD(browserCWD) {
  return {
    type: 'UPDATE_BROWSER_CWD',
    browserCWD
  }
};

export function updateClipboard(clipboard) {
  return {
    type: 'UPDATE_CLIPBOARD',
    clipboard
  }
};

export function resetApplication() {
  return {
    type: 'RESET_APPLICATION'
  }
};