import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, Dropdown, Modal, Button, Spinner, Form} from "react-bootstrap";
import { slide as Menu } from 'react-burger-menu'; // fallDown
import "./App.css";
import Routes from "./Routes";
import { connect } from 'react-redux'
import log from "./logger"
import config from "./config";
import { updateActivePlugin, updateSelection, updatePickFolderSelection, resetApplication, updateBrowserCWD, updateClipboard } from './actions/actionCreators'
import * as FSHelper from "./FSHelper";
import * as AccountHelper from "./AccountHelper";
import * as PluginHelper from "./PluginHelper";
import { withAlert } from 'react-alert'
import { formatAPIAlert } from './components/AlertTemplate'
import Browser from './containers/Browser'
import Uploader from './containers/Uploader'

const TAG = "App"

class App extends Component {

  constructor(props) {
    super(props);

     this.state = {
      menuOpen: false,
      modalShowDownload: false,
      modalShowAddToPlugin: false,
      modalShowRequestContent: false,
      modalShowMakeDirectory: false,
      modalShowDelete: false,
      modalShowRename: false,
      modalShowClipboard: false,
      modalShowUpload: false,
      alertShow: false,
      pickFolderShow: false,
      prepareDownload: false,
      prepareAddToPlugin: false,
      prepareRequestContent: false,
      prepareMakeDirectory: false,
      prepareDelete: false,
      prepareRename: false,
      preparePaste: false,
      downloadFile: "",
      renameFrom: "",
      filesAddedToPlugin: "",
      filesToDownload: [],
      filesToAddToPlugin: [],
      makeDirectoryName: "",
      renameTo: "",
      directoryCreated: "",
      deleteCompleted: "",
      renameCompleted: "",
      pasteCompleted: "",
      pmForRequestContent : [],
      clipboardAction: "",
    };
    // Download and addToPlugin prompts
    this.downloadFilesPrompt = this.downloadFilesPrompt.bind(this);
    this.addFilesToPluginPrompt = this.addFilesToPluginPrompt.bind(this);
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }

  // Save form field to state
  handleFormChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu () {
    this.props.updateActivePlugin({name:""})
    this.setState({menuOpen: false})
  }

  componentDidMount() {
    log.info(TAG, "componentDidMount", this);
  }

  // Either display RepFiles logo or current Plugin name
  renderNavTitle() {
      if (this.props.activePlugin.name) {
        return (
            <p className="largelogo" style={{whiteSpace: 'normal'}}>{' ' + this.props.activePlugin.name}</p>
        )
      }
      else {
        return (
            <Link title="Home" to="/" onClick={() => this.props.updateActivePlugin({name:""})}><img alt="" src="./images/logo64.png" width="195" height="64" className="d-inline-block align-top logo"/></Link>
        )
      }
  }

  // Either display Edition full name or Edition logo
  renderEditionSection() {
    if (!this.props.activePlugin.name) {
        return(
            <p className="text-logo" style={{whiteSpace: 'normal'}}>{' ' + this.props.account.application.name}</p>
        )
    }
    /*else {
        return (
             <Link title="Home" to="/" onClick={() => this.props.updateActivePlugin({name:""})}><img alt="" src="./images/nemra48.png" className="d-inline-block align-top"/></Link>
        )
    }*/
  }

  // Either display account icon or three dots dropdown menu
  // {this.props.location && this.props.location.pathname !== "/view/" ? <Dropdown.Item active={false} eventKey="3">Search</Dropdown.Item> : ""}
  // {this.props.location && this.props.location.pathname !== "/view/" ? <Dropdown.Item active={false} eventKey="2" onClick={() => this.sortItems()}>Sort</Dropdown.Item> : ""}

  renderRightSection() {
    if (this.props.activePlugin.name && this.props.location && this.props.location.pathname !== "/messages/") {
        return (
            <Dropdown alignRight>
              <Dropdown.Toggle variant="link" id="threedots" className="dropdown-toggle-ellipsis"></Dropdown.Toggle>
              <Dropdown.Menu>
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.uid !== config.conf.MYFILES_PLUGIN.uid ? <Dropdown.Item active={false} eventKey="1" onClick={() => this.requestContent(this.props.activePlugin)}>Request</Dropdown.Item> : ""}
                {this.props.location && this.props.location.pathname !== "/view/" ? <Dropdown.Item active={false} eventKey="4" onClick={() => this.selectAllItems()}>Select all files</Dropdown.Item> : ""}
                {this.props.location && this.props.location.pathname !== "/view/" ? <Dropdown.Item active={false} eventKey="5" onClick={() => this.clearSelection()}>Clear selection</Dropdown.Item> : ""}
                <Dropdown.Item active={false} eventKey="6" onClick={() => this.refresh()}>Refresh</Dropdown.Item>
                {this.props.location && this.props.location.pathname !== "/view/" ? <Dropdown.Divider />: ""}
                {this.props.location && this.props.location.pathname !== "/view/" ? <Dropdown.Item eventKey="7" active={false} disabled={this.props.selection.length > 0 ? false : true} onClick={() => this.downloadSelectionPrompt()}>Download</Dropdown.Item> : ""}
                {PluginHelper.isPluginInstalled(this.props.installedPlugins, config.conf.MYFILES_PLUGIN.uid) && this.props.activePlugin.uid !== config.conf.MYFILES_PLUGIN.uid && this.props.location && this.props.location.pathname !== "/view/"
                  ? <Dropdown.Item eventKey="8" active={false} disabled={this.getFilesFromSelection(this.props.selection).length > 0 ? false : true} onClick={() => this.downloadAddToPluginPrompt()}>Add to MyFiles</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true ? <Dropdown.Divider />: ""}
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="9" active={false} onClick={() => this.makeDirectoryPrompt()}>Create folder</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="10" active={false} disabled={this.props.selection.length > 0 ? false : true} onClick={() => this.deletePrompt(this.props.selection)}>Delete</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="11" active={false} disabled={this.props.selection.length === 1 ? false : true} onClick={() => this.renamePrompt(this.props.selection)}>Rename</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true ? <Dropdown.Divider />: ""}
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="12" active={false} disabled={this.props.selection.length > 0 ? false : true} onClick={() => this.clipboardPrompt(this.props.selection, "Cut")}>Cut</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="13" active={false} disabled={this.props.selection.length > 0 ? false : true} onClick={() => this.clipboardPrompt(this.props.selection, "Copy")}>Copy</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="14" active={false} disabled={this.props.clipboard && this.props.clipboard.items.length > 0 ? false : true} onClick={() => this.clipboardPrompt(this.props.clipboard.items, "Paste")}>Paste</Dropdown.Item>
                  : ""
                }
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true ? <Dropdown.Divider />: ""}
                {this.props.location && this.props.location.pathname !== "/view/" && this.props.activePlugin.manageSupport === true
                  ? <Dropdown.Item eventKey="15" active={false} disabled={false} onClick={() => this.uploadPrompt()}>Upload</Dropdown.Item>
                  : ""
                }
              </Dropdown.Menu>
            </Dropdown>
        )
    }
    else {
        return (
            <Navbar.Brand>
                <Dropdown alignRight>
                  <Dropdown.Toggle id="account-logout" variant="link" className="dropdown-account">
                    <img alt="" src="./images/account28.png" className="d-inline-block align-top"/>
                  </Dropdown.Toggle>
                   {this.props.account.isAuthenticated === true ?
                      <Dropdown.Menu>
                        <Dropdown.Item className="dropdown-account-name" disabled>{this.props.account.login}</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => this.props.resetApplication()}>Sign out</Dropdown.Item>
                      </Dropdown.Menu>
                    : ""
                   }


                </Dropdown>
            </Navbar.Brand>
        )
    }
  }

  // Select all files.
  selectAllItems() {
      log.info(TAG, "selectAllItems");
      if (this.props.browserContent && this.props.browserContent["items"]) {
        let newSelection = []
        const checkboxes = document.getElementsByName('itemBox');
        for(let j=0;j<checkboxes.length;j++) {
          if (!checkboxes[j].id.endsWith("/")) {
            let found = false;
            let file = {}
            // Find TFile in browserContent
            for (let i=0; i < this.props.browserContent["items"].length; i++) {
                if (checkboxes[j].id === this.props.browserContent["items"][i].absolutePath)
                {
                    file = this.props.browserContent["items"][i];
                    found = true;
                    break;
                }
            }
            if (found === true)
            {
                checkboxes[j].checked = true;
                // Find TFile in selection and update accordingly
                found = false;
                for (let i=0; i < this.props.selection.length; i++) {
                  if (file.absolutePath === this.props.selection[i].absolutePath) {
                      file.selected = true;
                      found = true;
                      break;
                  }
                }
                if (found === false) {
                    newSelection.push(file)
                }
            }
          }
        }
        // Update final selection
        this.props.updateSelection(this.props.selection.concat(newSelection));
      }
  }

  // Clear full selection
  clearSelection() {
    const checkboxes = document.getElementsByName('itemBox');
    for(let i=0;i<checkboxes.length;i++) {
      checkboxes[i].checked = false;
    }
    this.props.updateSelection([]);
  }

  async createDirectory(currentFolder, name, plugin) {
    if (currentFolder && currentFolder.absolutePath && name && name.length > 0 && plugin && plugin.uid) {
        if (!currentFolder.absolutePath.endsWith("/")) {
            currentFolder.absolutePath = currentFolder.absolutePath + "/";
        }
        let path = currentFolder.absolutePath + name.trim();
        log.info(TAG, "createDirectory", path);
        this.setState({ modalShowMakeDirectory: true,  prepareMakeDirectory: true});
        try {
          let payload = await FSHelper.makeDirectoryAPI(this.props.account.token, {"absolutePath": path, "name": name, "type": -1, "ext": plugin.uid});
          log.info(TAG, "createDirectory", payload);
          this.setState({ prepareMakeDirectory: false, directoryCreated: payload});
        } catch (e) {
          log.error(TAG, "createDirectory", e);
          this.modalCloseMakeDirectory();
          this.props.alert.error(formatAPIAlert(e));
        }
        finally {
          // Refresh current working directory
          this.props.updateBrowserCWD({"update" : true});
          this.modalCloseMakeDirectory();
        }
    }
  }

  async deleteItems(items) {
    if (items && items.length > 0) {
        log.info(TAG, "deleteItems", items);
        this.setState({ modalShowDelete: true,  prepareDelete: true});
        try {
          let payload = await FSHelper.deleteAPI(this.props.account.token, items);
          log.info(TAG, "deleteItems", payload);
          this.clearSelection();
          this.setState({ prepareDelete: false, deleteCompleted: payload});
        } catch (e) {
          log.error(TAG, "deleteItems", e);
          this.modalCloseDelete();
          this.props.alert.error(formatAPIAlert(e));
        }
        finally {
          // Refresh current working directory
          this.props.updateBrowserCWD({"update" : true});
          this.modalCloseDelete();
        }
    }
  }

  async renameItem(fromItem, currentFolder, name, plugin) {
    if (fromItem && fromItem.absolutePath && currentFolder && currentFolder.absolutePath && name && name.length > 0 && plugin && plugin.uid) {
        if (!currentFolder.absolutePath.endsWith("/")) {
            currentFolder.absolutePath = currentFolder.absolutePath + "/";
        }
        let path = currentFolder.absolutePath + name.trim();
        log.info(TAG, "renameItem", fromItem.absolutePath, "to", path);
        this.setState({ modalShowRename: true,  prepareRename: true});
        try {
          let payload = await FSHelper.renameAPI(this.props.account.token, fromItem, {"absolutePath": path, "name": name, "type": fromItem.type, "ext": plugin.uid});
          log.info(TAG, "renameItem", payload);
          this.clearSelection();
          this.setState({ prepareRename: false, renameCompleted: payload});
        } catch (e) {
          log.error(TAG, "renameItem", e);
          this.modalCloseRename();
          this.props.alert.error(formatAPIAlert(e));
        }
        finally {
          // Refresh current working directory
          this.props.updateBrowserCWD({"update" : true});
          this.modalCloseRename();
        }
    }
  }

  async requestContent(plugin) {
    if (plugin && plugin.uid) {
        log.info(TAG, "requestContent");
        this.setState({ modalShowRequestContent: true,  prepareRequestContent: true, pmForRequestContent: ""});
        try {
          let payload = await PluginHelper.requestContentPlugin(this.props.account.token, plugin.uid);
          log.info(TAG, "requestContent", payload);
          this.setState({ prepareRequestContent: false, pmForRequestContent: payload});
        } catch (e) {
          log.error(TAG, "requestContent", e);
          this.modalCloseRequestContent();
          this.props.alert.error(formatAPIAlert(e));
        }
    }
  }

  generateLinkForRequestContent(pluginManagers, pluginName, application) {
      let recipients = "";
      if (pluginManagers && pluginManagers.pluginAdmins && pluginManagers.pluginAdmins.length > 0) {
        for (let i=0; i<pluginManagers.pluginAdmins.length; i++) {
            let pluginAdmin = pluginManagers.pluginAdmins[i];
            recipients = recipients + pluginAdmin.adminEmail + ',';
        }
        if (recipients.endsWith(",")) {
            recipients = recipients.substring(0, recipients.length - 1)
        }
      }
      const mailtoRequestContentLink = 'mailto:' + recipients + '?subject=' + 'RepFiles content request for ' + pluginName + '&body=' + '%0D%0A%0D%0APlugin: '+pluginName + '%0D%0AApplication: '+application;
      // log.info(TAG, mailtoRequestContentLink);
      return mailtoRequestContentLink;
  }

  sortItems() {
    log.info(TAG, "sortItems");
  }

  // Filter list by returning files only
  getFilesFromSelection(items) {
    let newSelection = [];
    if (items && items.length > 0) {
        for (let i=0; i < items.length; i++) {
            if (items[i].type === 0) {
                newSelection.push(items[i]);
            }
        }
    }
    return newSelection;
  }

  // Display download prompt with selection
  downloadSelectionPrompt() {
    // Get selected items absolute path to download
    const filesToDownload = []
    for(let i=0;i<this.props.selection.length;i++) {
        filesToDownload.push(this.props.selection[i]);
    }
    if (filesToDownload.length > 0) {
        this.setState({ modalShowDownload: true, filesToDownload: filesToDownload});
    }
  }

  // Display download prompt
  downloadFilesPrompt(filesToDownload) {
    if (filesToDownload.length > 0) {
        this.setState({ modalShowDownload: true, filesToDownload: filesToDownload});
    }
  }

  // Display Add to Plugin prompt from selection
  downloadAddToPluginPrompt() {
    // Get selected items absolute path to add
    const filesToAddToPlugin = []
    for(let i=0;i<this.props.selection.length;i++) {
        filesToAddToPlugin.push(this.props.selection[i]);
    }
    if (filesToAddToPlugin.length > 0) {
        this.setState({ modalShowAddToPlugin: true, filesToAddToPlugin: filesToAddToPlugin, pickFolderShow: true});
    }
  }

  // Display Add to Plugin prompt
  addFilesToPluginPrompt(filesToAddToPlugin) {
    if (filesToAddToPlugin.length > 0) {
        this.props.updatePickFolderSelection({})
        this.setState({ modalShowAddToPlugin: true, filesToAddToPlugin: filesToAddToPlugin, pickFolderShow: true});
    }
  }

  // Display make directory prompt
  makeDirectoryPrompt() {
    this.setState({ modalShowMakeDirectory: true, makeDirectoryName: "", directoryCreated: ""});
  }

  // Display delete prompt
  deletePrompt(filesToDelete) {
    if (filesToDelete.length > 0) {
        this.setState({ modalShowDelete: true, prepareDelete: false, deleteCompleted: ""});
    }
  }

  // Display rename prompt
  renamePrompt(filesToDelete) {
    if (filesToDelete.length === 1) {
        this.setState({ modalShowRename: true, prepareRename: false, renameCompleted: "", renameFrom: filesToDelete[0], renameTo: ""});
    }
  }

  // Display clipboard prompt
  clipboardPrompt(items, clipboardAction) {
    if ((items) && (items.length >= 1)) {
        this.setState({ modalShowClipboard: true, clipboardAction: clipboardAction});
    }
  }

  // Add to clipboard
  clipboardAdd(itemsToAdd, clipboardAction) {
    if ((itemsToAdd) && (itemsToAdd.length >= 1)) {
        let isCut = false;
        if (clipboardAction === "Cut") {
            isCut = true;
        }
        this.props.updateClipboard({"cut": isCut, "items" : itemsToAdd})
        this.clearSelection();
        this.setState({ modalShowClipboard: false});
    }
  }

  async clipboardPaste(clipboard, targetFolder) {
    log.info(TAG, "clipboardPaste", targetFolder, clipboard)
    if ((clipboard) && (targetFolder) && (clipboard.items.length >= 1)) {
        this.setState({ preparePaste: true, pasteCompleted: ""});
        try {
          let payload = await FSHelper.copyAPI(this.props.account.token, clipboard.items, targetFolder);
          log.info(TAG, "clipboardPaste", payload);
          if (clipboard.cut === true) {
             let payload = await FSHelper.deleteAPI(this.props.account.token, clipboard.items);
             log.info(TAG, "Delete", payload);
          }
          this.clearSelection();
          this.setState({ preparePaste: false, pasteCompleted: ""});
        } catch (e) {
          log.error(TAG, "clipboardPaste", e);
          this.modalCloseClipboard();
          this.props.alert.error(formatAPIAlert(e));
        }
        finally {
          // Refresh current working directory
          this.props.updateBrowserCWD({"update" : true});
          // Clear clipboard
          this.props.updateClipboard({"cut": false, "items" : []})
          this.setState({ modalShowClipboard: false});
        }
    }
  }

  // Display upload panel
  uploadPrompt() {
    this.setState({ modalShowUpload: true});
  }

  refresh() {
    log.info(TAG, "refresh");
    window.location.reload();
  }

  modalCloseDownload() {
    this.setState({ modalShowDownload: false, prepareDownload: false, downloadFile: "" });
  }

  modalCloseAddToPlugin() {
    this.props.updatePickFolderSelection({})
    this.setState({ modalShowAddToPlugin: false, prepareAddToPlugin: false, filesAddedToPlugin: "" });
  }

  modalCloseRequestContent() {
    this.setState({ modalShowRequestContent: false, prepareRequestContent: false, pluginForRequestContent: "" });
  }

  modalCloseMakeDirectory() {
    this.setState({ modalShowMakeDirectory: false, prepareMakeDirectory: false, makeDirectoryName: "", directoryCreated: ""});
  }

  modalCloseDelete() {
    this.setState({ modalShowDelete: false, prepareDelete: false});
  }

  modalCloseRename() {
    this.setState({ modalShowRename: false, prepareRename: false});
  }

  modalCloseClipboard() {
    this.setState({ modalShowClipboard: false, preparePaste: false, clipboardAction: ""});
  }

  modalCloseUpload() {
    this.setState({ modalShowUpload: false});
  }

  modalCloseUploadAndRefresh() {
    this.setState({ modalShowUpload: false});
    this.props.updateBrowserCWD({"update" : true});
  }

  // Trigger ZIP generation on server-side. Returns S3RemoteTFile when ready.
  async downloadItemsZIP(items) {
    log.info(TAG, "downloadItemsZIP", items);
    this.setState({ prepareDownload: true });
    let payload = {};
    try {
      // File with presigned URL to download archive
      payload = await FSHelper.downloadFilesZIPAPI(this.props.account.token, items);
      log.info(TAG, "downloadItemsZIP", payload);
      this.setState({ prepareDownload: false, downloadFile: payload ? payload : ""});
      // Clear selection after download archive available
      this.clearSelection();
    } catch (e) {
      log.error(TAG, "downloadItemsZIP", e);
      this.modalCloseDownload();
      this.props.alert.error(formatAPIAlert(e));
    }
    return payload;
  }

  // Trigger Share generation on server-side. Returns S3RemoteTFile when ready.
  async downloadSingleItem(items) {
    log.info(TAG, "downloadSingleItem", items);
    let payload = {};
    if (items.length === 1) {
        this.setState({ prepareDownload: true });
        try {
          // File with presigned URL to download archive
          payload = await FSHelper.downloadFilesShareSaveAs(this.props.account.token, items);
          log.info(TAG, "downloadSingleItem", payload);
          this.setState({ prepareDownload: false, downloadFile: payload ? payload[0] : ""});
          // Clear selection after download is available
          this.clearSelection();
        } catch (e) {
          log.error(TAG, "downloadSingleItem", e);
          this.modalCloseDownload();
          this.props.alert.error(formatAPIAlert(e));
        }
    }
    return payload;
  }


  // Trigger add to MyFiles on server-side. Returns S3RemoteTFile list when completed.
  async addItemsToMyFiles(items, targetFolder) {
    this.setState({ prepareAddToPlugin: true , pickFolderShow: false});
    let payload = {};
    try {
      let addItems = {
         'target': targetFolder && targetFolder.absolutePath ? targetFolder : {'absolutePath': "/", 'type': 1},
         'files': items,
      }
      log.info(TAG, "addItemsToMyFiles", addItems)
      payload = await FSHelper.downloadFilesAddToMyFiles(this.props.account.token, addItems);
      log.info(TAG, "addItemsToMyFiles completed", payload);
      this.setState({ prepareAddToPlugin: false, filesAddedToPlugin: payload ? payload : ""});
      // Clear selection after addItemsToMyFiles
      this.clearSelection();
      this.props.updatePickFolderSelection({})
    } catch (e) {
      log.error(TAG, "addItemsToMyFiles", e);
      this.modalCloseAddToPlugin();
      this.props.alert.error(formatAPIAlert(e));
    }
    return payload;
  }

  render() {

    const childProps = {...this.props,
                        ...{'downloadFilesPrompt': this.downloadFilesPrompt},
                        ...{'addFilesToPluginPrompt': this.addFilesToPluginPrompt}};
    /*const childProps = Object.assign({}, this.props, {'downloadFilesPrompt': this.downloadFilesPrompt}, {'addFilesToPluginPrompt': this.addFilesToPluginPrompt});*/


    return (
    <div id="outer-container" className="App container">

      <div className="App container">

        <Menu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} width={240}
          isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
          <Link className="menu-item home-icon" onClick={() => this.closeMenu()} to="/">Home</Link>
          <Link className="menu-item message-icon" onClick={() => this.closeMenu()} to="/messages">Messages</Link>
          {this.props.account.application.category !== AccountHelper.GENERIC_EDITION.category ?
           <Link className="menu-item addplugin-icon" onClick={() => this.closeMenu()} to="/addnew">Add plugin</Link>
           : ""}
          <Link className="menu-item help-icon" onClick={() => this.closeMenu()} to="/support">Help & Feedback</Link>
        </Menu>

        <main id="page-wrap" className="pagewrap-container">

          <Modal show={this.state.modalShowDownload} onHide={() => this.modalCloseDownload()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.filesToDownload.length > 1 ?
              <p>{this.state.filesToDownload.length} items will be added to a zipped folder prior to download.</p>
            : <p>1 file selected for download.</p>
            }
            {this.state.prepareDownload ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Preparing file to download, please wait...</span></div> : ""}
            {this.state.downloadFile ? <p className="App" style={{fontWeight: 'bold'}}>{this.state.filesToDownload.length > 1 ? "Files are ready": "File is ready"} ({FSHelper.getBytes(this.state.downloadFile.size)}). <a href={this.state.downloadFile.presignedUrl}>Download now</a></p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.prepareDownload || this.state.downloadFile} onClick={() => this.modalCloseDownload()}>Cancel</Button>
              { this.state.filesToDownload.length > 1 ?
                <Button variant="success" disabled={this.state.prepareDownload || this.state.downloadFile} onClick={() => this.downloadItemsZIP(this.state.filesToDownload)}>Proceed</Button>
              : this.state.filesToDownload.length === 1 && this.state.filesToDownload[0].type === 0 ? // Single file download, otherwise folder.
                <Button variant="success" disabled={this.state.prepareDownload || this.state.downloadFile} onClick={() => this.downloadSingleItem(this.state.filesToDownload)}>Proceed</Button>
                : <Button variant="success" disabled={this.state.prepareDownload || this.state.downloadFile} onClick={() => this.downloadItemsZIP(this.state.filesToDownload)}>Proceed</Button>
              }
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowAddToPlugin} onHide={() => this.modalCloseAddToPlugin()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Add To MyFiles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p> {this.state.filesToAddToPlugin.length} item{this.state.filesToAddToPlugin.length > 1 ? "s": ""} will be added to MyFiles. Select an optional target folder.</p>
            {this.state.pickFolderShow ? <Browser overridePlugin={config.conf.MYFILES_PLUGIN} typeFilter="folder" dropBackTitle={true} itemSelector='radio'/> : ""}
            {this.state.prepareAddToPlugin ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Adding items to MyFiles, please wait...</span></div> : ""}
            {this.state.filesAddedToPlugin ? <p className="App" style={{fontWeight: 'bold'}}>Files added to MyFiles successfully. <Link to={{pathname: "/browse/", state: {plugin: config.conf.MYFILES_PLUGIN, action: 'reload'}}} onClick={() => this.modalCloseAddToPlugin()}>Go to MyFiles</Link></p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.prepareAddToPlugin || this.state.filesAddedToPlugin} onClick={() => this.modalCloseAddToPlugin()}>Cancel</Button>
              <Button variant="success" disabled={this.state.prepareAddToPlugin || this.state.filesAddedToPlugin} onClick={() => this.addItemsToMyFiles(this.state.filesToAddToPlugin, this.props.pickFolderSelection)}>Proceed</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowRequestContent} onHide={() => this.modalCloseRequestContent()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Request Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.prepareRequestContent ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Initializing Request Content , please wait...</span></div> : ""}
            {this.state.pmForRequestContent ? <p className="App" style={{fontWeight: 'bold'}}><a href={this.generateLinkForRequestContent(this.state.pmForRequestContent, this.props.activePlugin.name, this.props.account.application.fullname)} target="_blank" rel="noopener noreferrer">Email content managers</a> to request additional content or make suggestions.</p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.prepareRequestContent} onClick={() => this.modalCloseRequestContent()}>Cancel</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowMakeDirectory} onHide={() => this.modalCloseMakeDirectory()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Create folder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!this.state.prepareMakeDirectory && !this.state.directoryCreated ?
                <Form>
                  <Form.Group controlId="makeDirectoryName">
                    <Form.Label>Folder name:</Form.Label>
                    <Form.Control type="text" value={this.state.makeDirectoryName} onChange={this.handleFormChange}/>
                    <Form.Text className="text-muted">Folder name cannot be empty</Form.Text>
                  </Form.Group>
                </Form>
             : "" }
            {this.state.prepareMakeDirectory ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Creating folder, please wait...</span></div> : ""}
            {this.state.directoryCreated ? <p className="App" style={{fontWeight: 'bold'}}> Folder created successfully.</p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.prepareMakeDirectory || this.state.directoryCreated} onClick={() => this.modalCloseMakeDirectory()}>Cancel</Button>
              <Button variant="success" disabled={this.state.prepareMakeDirectory || this.state.directoryCreated || !this.props.browserContent["folder"] || !this.props.browserContent["folder"].absolutePath || this.state.makeDirectoryName.trim().length < 1} onClick={() => this.createDirectory(this.props.browserContent["folder"], this.state.makeDirectoryName.trim(), this.props.activePlugin)}>Proceed</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowDelete} onHide={() => this.modalCloseDelete()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!this.state.deleteCompleted ?
              <p>{this.props.selection.length} item{this.props.selection.length > 1 ? "s": ""} from selection will be deleted. Folders will be deleted recursively if any in selection.</p>
              : ""}
            {this.state.prepareDelete ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Deleting, please wait...</span></div> : ""}
            {this.state.deleteCompleted ? <p className="App" style={{fontWeight: 'bold'}}> Delete completed successfully.</p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.prepareDelete || this.state.deleteCompleted} onClick={() => this.modalCloseDelete()}>Cancel</Button>
              <Button variant="success" disabled={this.state.prepareDelete || this.state.deleteCompleted} onClick={() => this.deleteItems(this.props.selection)}>Proceed</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowRename} onHide={() => this.modalCloseRename()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Rename</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!this.state.prepareRename && !this.state.renameCompleted ?
                <Form>
                  <Form.Group controlId="renameTo">
                    <Form.Label>Rename <span style={{fontWeight: '600'}}>{FSHelper.getBase(this.state.renameFrom.name)}</span> to:</Form.Label>
                    <br/>
                    <Form.Control type="text" value={this.state.renameTo} onChange={this.handleFormChange}/>
                    <Form.Text className="text-muted">New name cannot be empty. File extension cannot be modified.</Form.Text>
                  </Form.Group>
                </Form>
             : "" }
            {this.state.prepareRename ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Renaming, please wait...</span></div> : ""}
            {this.state.renameCompleted ? <p className="App" style={{fontWeight: 'bold'}}> Rename completed successfully.</p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.prepareRename || this.state.renameCompleted} onClick={() => this.modalCloseRename()}>Cancel</Button>
              <Button variant="success" disabled={this.state.prepareRename || this.state.renameCompleted || !this.props.browserContent["folder"] || !this.props.browserContent["folder"].absolutePath || this.state.renameTo.trim().length < 1} onClick={() => this.renameItem(this.state.renameFrom, this.props.browserContent["folder"], this.state.renameTo.trim() + FSHelper.getExtension(this.state.renameFrom.name), this.props.activePlugin)}>Proceed</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowClipboard} onHide={() => this.modalCloseClipboard()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Clipboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!this.state.preparePaste?
                 !(this.state.clipboardAction === "Paste")?
                    <p>{this.state.clipboardAction} {this.props.selection.length} item{this.props.selection.length > 1 ? "s": ""} to clipboard?</p>
                  : <p>{this.state.clipboardAction} {this.props.clipboard.items.length} item{this.props.clipboard.items.length > 1 ? "s": ""} from clipboard?</p>
              : "" }
            {this.state.preparePaste ? <div><Spinner animation="border" size="sm" /><span style={{fontWeight: 'bold', verticalAlign: '-3px'}}> Pasting from clipboard, please wait...</span></div> : ""}
            {this.state.pasteCompleted ? <p className="App" style={{fontWeight: 'bold'}}> Paste completed successfully.</p> : ""}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled={this.state.preparePaste || this.state.pasteCompleted} onClick={() => this.modalCloseClipboard()}>Cancel</Button>
              {!(this.state.clipboardAction === "Paste")?
                 <Button variant="success" disabled={this.state.preparePaste || this.state.pasteCompleted} onClick={() => this.clipboardAdd(this.props.selection, this.state.clipboardAction)}>Proceed</Button>
               : <Button variant="success" disabled={this.state.preparePaste || this.state.pasteCompleted} onClick={() => this.clipboardPaste(this.props.clipboard, this.props.browserContent.folder)}>Proceed</Button>
              }
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.modalShowUpload} onHide={() => this.modalCloseUpload()} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Uploader loglevel={1} paramfile="uploadfile" maxfilesize={1024*1024*784} folderdepth={0}
                      resume={false} retry={3} chunksize={1024*1024*4}
                      blacklist={"\\.(exe|inf|vbs|wsf|bat|cmd|reg|ocx|dll|tlb|tmp)$"}
                      url={config.apiGatewayFSManageUpload.URL}
                      onUploaderTransferDone={() => this.modalCloseUploadAndRefresh()}/>
            </Modal.Body>
          </Modal>

          <Navbar collapseOnSelect bg="dark" variant="dark">
            <Navbar.Brand>
            {
                this.renderNavTitle()
            }
            </Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse>
                <Nav className="ml-auto">
                  <Navbar.Brand className="d-inline-block align-top">
                  {
                     this.renderEditionSection()
                  }
                  </Navbar.Brand>
                  {
                     this.renderRightSection()
                  }
                </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps}/>
        </main>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    account: state.account,
    activePlugin: state.activePlugin,
    selection: state.selection,
    pickFolderSelection: state.pickFolderSelection,
    browserContent: state.browserContent,
    browserCWD: state.browserCWD,
    clipboard: state.clipboard,
    installedPlugins: state.installedPlugins,
  }
}
const mapDispatchToProps = { updateActivePlugin, updateSelection, updatePickFolderSelection, resetApplication, updateBrowserCWD, updateClipboard }
const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
const connectedAppRouter = withRouter(connectedApp);

export default withAlert()(connectedAppRouter);
