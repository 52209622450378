import React from "react";
import { Redirect, Route } from 'react-router-dom'

const AppliedRoute = ({ component: C, props: cProps, ...rest }) => {

    if (cProps && cProps.account && cProps.account.isAuthenticated) {
        return (
            <Route {...rest} render={props => <C {...props} {...cProps} />} />
        )
    }
    else {
        // Redirect to login page if not authenticated
        return (
            <Redirect to={{pathname: "/account", state: { from: cProps.location } }} />
        )
    }

}

export default AppliedRoute