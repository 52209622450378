import { API } from "aws-amplify";
import log from "./logger"

const TAG = "MessagesHelper"

export const CATEGORY_MESSAGE = 1;
export const CATEGORY_NOTIFICATION_PUSH = 2;
export const USERSTATUS_UNREAD = 1;
export const USERSTATUS_READ = 2;
export const USERSTATUS_READ_REMOVED = 3;
export const USERSTATUS_DISCARDED = 4;
export const TRIGGER_APPLICATION = "APP";
export const TRIGGER_NOTIFICATION = "NOT";

// Load messages from API
function loadMessagesAPI(token, items) {
    let init = {
        headers: {"Authorization": "Bearer " + token},
        body: items,
    }
    return API.post("messageslist", "", init);
}

function loadMessagesStatusAPI(token, msgstatus) {
    let init = {
        headers: {"Authorization": "Bearer " + token},
        body: msgstatus,
    }
    return API.post("messagesstatus", "", init);
}

export function formatDate(lastModified) {
    return (new Date(lastModified)).toLocaleDateString()
}

export function formatTime(lastModified) {
    let date = new Date(lastModified);
    let hh = date.getUTCHours();
    var ampm = hh >= 12 ? 'PM' : 'AM';
    hh = hh % 12;
    hh = hh ? hh : 12;
    let mm = date.getUTCMinutes();
    if (hh < 10) {hh = "0"+hh;};
    if (mm < 10) {mm = "0"+mm;};
    let t = hh + ":" + mm + " " + ampm;
    return t;
}

// Sort by status and date.
function compare(a, b) {
    const statusA = a.userStatus;
    const statusB = b.userStatus;
    if (statusA > statusB) return 1;
    else if (statusA < statusB) return -1;
    else {
        if (a.dateTime > b.dateTime) return -1;
        else if (a.dateTime < b.dateTime) return 1;
        else return 0;
    };
}

// Load Messages for installed plugins.
export async function loadMessages(token, uids) {
    let messages = [];
    try {
      const payload = await loadMessagesAPI(token, uids);
      let messagesPayload = payload.messages;
      // Flatten messages
      for (let key in messagesPayload) {
        let mList = messagesPayload[key];
        mList.forEach((item) => {
          item.parentId = key;
          messages.push(item);
        });
      }
      // Sort by status and date.
      messages.sort(compare);
    } catch (e) {
      log.error(TAG, "loadMessages", e);
      throw e;
    }
    return messages;
}

// Set messages status.
export async function loadMessagesStatus(token, msgStatusPayload) {
    let ret = false;
    try {
      const payload = await loadMessagesStatusAPI(token, msgStatusPayload);
      ret = payload.response;
    } catch (e) {
      log.error(TAG, "loadMessagesStatus", e);
      throw e;
    }
    return ret;
}

