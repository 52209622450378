import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import rootReducer from "./reducers/rootReducer";

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['account', 'installedPlugins', 'activePlugin', 'selection', 'pickFolderSelection', 'browserContent', 'browserCWD', 'clipboard'] // only these properties will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export function configurePersistedStore(initialState) {
  let store = createStore(persistedReducer, initialState)
  let persistor = persistStore(store)
  return { store, persistor }
}

export function configureStore(initialState) {
  const store = createStore(rootReducer, initialState)
  return store
}
