import React, { Component } from "react";
import LoaderSpinner from "../components/LoaderSpinner";
import { Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import * as MessagesHelper from "../MessagesHelper";
import log from "../logger"
import "./Message.css";
import { withAlert } from 'react-alert'
import { formatAPIAlert } from '../components/AlertTemplate'

const TAG = "Message"

class Message extends Component {

  constructor(props) {
    super(props);

    const msg = props.location.state? props.location.state.message : {};
    this.state = {
      isLoading: false,
      message : msg,
    };

  }

  async componentDidMount() {
    // Mark message as read immediately once opened.
    log.info(TAG, "componentDidMount", this);
    await this.markAsRead(this.state.message);
  }

  async setSingleMessageStatus(msg, status) {
    let ret = false;
    this.setState({ isLoading: true });
    try {
        let msgStatus = {
            "msgids": ["" + msg.msgid],
            "status":status,
            "trigger": MessagesHelper.TRIGGER_APPLICATION
        }
        ret = await MessagesHelper.loadMessagesStatus(this.props.account.token, msgStatus);
    } catch (e) {
        this.props.alert.error(formatAPIAlert(e));
    }
    this.setState({ isLoading: false });
    return ret;
  }

  async markAsRead(msg) {
    log.info(TAG, "markAsRead", msg.msgid);
    let ret = await this.setSingleMessageStatus(msg, MessagesHelper.USERSTATUS_READ);
    return ret;
  }

  async keepAsUnread(msg) {
    log.info(TAG, "keepAsUnread", msg.msgid);
    await this.setSingleMessageStatus(msg, MessagesHelper.USERSTATUS_UNREAD);
    this.props.history.push("/messages/");
  }

  async deleteMessage(msg) {
    log.info(TAG, "deleteMessage", msg.msgid);
    await this.setSingleMessageStatus(msg, MessagesHelper.USERSTATUS_READ_REMOVED);
    this.props.history.push("/messages/");
  }

  backToMessages(msg) {
    this.props.history.push("/messages/");
  }

  linkToFile(msg, link) {
    let type = 0;
    let name = link;
    let mimeType = "";
    if (link.endsWith("/")) {
        type = 1;
        name = name.substring(0, (name.length-1));
    }
    let ind = name.lastIndexOf("/");
    if (ind >= 0) name = name.substring(ind + 1, name.length);
    if (!link.startsWith("/")) {link = "/" + link;}
    let file = {"type": type, "name": name, "absolutePath": link, "ext": msg.parentId, "mimeType": mimeType, "size": -1, "lastModified": -1};
    return file
  }

  linkToFolder(msg, link) {
    let type = 1;
    let path = link;
    if (!path.endsWith("/")) {
        let ind = path.lastIndexOf("/");
        if (ind > 0) path = path.substring(0, ind + 1);
    }
    if (!path.startsWith("/")) {path = "/" + path;}
    let folder = {"type": type, "name": "", "absolutePath": path, "ext": msg.parentId};
    return folder
  }

  // <Link to={{pathname: '/view/', state: { file: this.linkToFile(msg, link) }}}>
  renderRelatedFiles(msg) {
    if (msg.links && msg.links.length > 0) {
        return (
            <div>
                <div className="mrow-files">Related files</div>
                <div>
                {msg.links.map((link) => (
                   <div className="file-link" key={link}>
                    <Link to={{pathname: '/browse/', state: { plugin: {uid: msg.parentId, name: msg.parentName}, initialFolder: this.linkToFolder(msg, link) }}}>
                      <div>{link}</div>
                    </Link>
                   </div>
                ))}
                </div>
             </div>
        )
    }
    else {
        return ("")
    }
  }


  renderMessage(msg) {
    return (
      <div className="message-container">
        <div className="micon">
           <Image src={msg.iconUrl} width='72' height="72"/>
        </div>
        <div className="mcontainer">
            <div className="mrow-title">
              <span className="mcolumn ellipsis subject">{msg.subject}</span>
              <span className="mcolumn ellipsis date">Sent {MessagesHelper.formatDate(msg.dateTime)} @ {MessagesHelper.formatTime(msg.dateTime)} GMT</span>
            </div>
            <div className="mrow-body">
              <span className="mcolumn body">{parse(msg.body.replace(/\n/g, '<br />'))}</span>
            </div>
            {this.renderRelatedFiles(msg)}
            <div className="mrow-buttons">
              <div className="mrow-back">
                <Button variant="link" onClick={() => this.backToMessages(msg)}>Back</Button>{" "}
              </div>
              <div className="mrow-status">
                <Button variant="link" onClick={() => this.deleteMessage(msg)}>Delete message</Button>{" "}
                <Button variant="success" onClick={() => this.keepAsUnread(msg)}>Keep as unread</Button>
              </div>
            </div>

        </div>
       </div>
    )
  }

  render() {
    return (
      <div className="Message">
        {!this.state.isLoading ?
            this.renderMessage(this.state.message):
            <LoaderSpinner loadingText="Loading ..."/>
        }
      </div>
    )
  }
}

const connectedMessage = Message;
export default withAlert()(connectedMessage);