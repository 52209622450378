import React, { Component } from "react";
import LoaderSpinner from "../components/LoaderSpinner";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as MessagesHelper from "../MessagesHelper";
import log from "../logger"
import { withAlert } from 'react-alert'
import { connect } from 'react-redux'
import { formatAPIAlert } from '../components/AlertTemplate'
import "./Messages.css";

const TAG = "Messages"

class Messages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      messages : [],
    };

  }

  async componentDidMount() {
    await this.loadItems();
    log.info(TAG, "componentDidMount", this);
  }

  async loadItems() {
    const start = new Date().getTime();
    this.setState({ isLoading: true });
    try {
      // Flat messages sorted by userStatus and date.
      const uids = [];
      for(let i=0;i<this.props.installedPlugins.length;i++) {
        if (this.props.installedPlugins[i].installed === true) {
            uids.push(this.props.installedPlugins[i].uid);
        }
      }
      const itemMessages = await MessagesHelper.loadMessages(this.props.account.token, uids);
      // List installed plugins and update message properties.
      if (this.props.installedPlugins && itemMessages) {
        this.props.installedPlugins.forEach((installedPlugin, pluginIdx) => {
            for (let idx in itemMessages) {
                let itemMessage = itemMessages[idx];
                if ((itemMessage.parentId === installedPlugin.uid) || (itemMessage.parentId === this.props.account.application.category)) {
                    // Assign matching plugin icon to each message.
                    itemMessage.iconUrl = (itemMessage.parentId === installedPlugin.uid) ? installedPlugin.iconUrl : this.props.account.application.iconUrl;
                    itemMessage.parentName = installedPlugin.name;
                }
            }
        });
      }
      this.setState({ messages: itemMessages});
    } catch (e) {
      this.props.alert.error(formatAPIAlert(e));
    }
    this.setState({ isLoading: false });
    const duration = new Date().getTime() - start;
    log.info(TAG, "loadItems completed in " + duration);
  }

  async markAllAsRead(unreadMessages) {
    log.info(TAG, "Mark all as Read");
    this.setState({ isLoading: true });
    try {
        let msgids = []
        for (let i=0; i < unreadMessages.length; i++) {
            msgids.push("" + unreadMessages[i].msgid)
        }
        let msgStatus = {
            "msgids": msgids,
            "status": MessagesHelper.USERSTATUS_READ,
            "trigger": MessagesHelper.TRIGGER_APPLICATION
        }
        await MessagesHelper.loadMessagesStatus(this.props.account.token, msgStatus);
    } catch (e) {
        this.props.alert.error(formatAPIAlert(e));
    }
    await this.loadItems();
  }

  // Render all messages for each plugin
  renderAllItemsList(messages) {
    const unreadMessages = [];
    messages.forEach((msg) => {
        if (msg.userStatus === 1) {
            unreadMessages.push(msg);
        }
    });
    const readMessages = [];
    messages.forEach((msg) => {
        if (msg.userStatus === 2) {
            readMessages.push(msg);
        }
    });

    if (messages && messages.length > 0) {
        return (
          <div id="messages-container">
              { unreadMessages.length > 0 ?
                    <div>
                      <div className="status-bar">
                          <div className="status-unread">Unread messages</div>
                          <div className="status-button"><Link className="status-button-link" to='/messages' onClick={() => this.markAllAsRead(unreadMessages)}>MARK ALL AS READ</Link></div>
                      </div>
                      <div id="messages-unread" key="unread">
                      { unreadMessages.map((message) => (this.renderItem(message))) }
                      </div>
                    </div>
                : ""
              }
              { readMessages.length > 0 ?
                  <div>
                      <div className="status-read">Read messages</div>
                      <div id="messages-read" key="read" className="messages-read-content">
                      { readMessages.map((message) => (this.renderItem(message)))}
                      </div>
                  </div>
              : ""
              }
          </div>
        )
    }
    else {
        return (
            <div id="messages-container">
                No message available
            </div>
        )
    }
  }

  // Render a single message
  renderItem(msg) {
    return (
      <div className="message-container" key={msg.msgid}>
      <Link to={{pathname: "/message/", state: {message: msg}}}>
        <div className="micon">
           <Image src={msg.iconUrl} width='72' height="72"/>
        </div>
        <div className="mcontainer">
            <div className="mrow">
              <span className="mcolumn ellipsis subject">{msg.subject}</span>
              <span className="mcolumn ellipsis body">{msg.body}</span>
              <span className="mcolumn ellipsis date">Sent {MessagesHelper.formatDate(msg.dateTime)} @ {MessagesHelper.formatTime(msg.dateTime)} GMT</span>
              <span className="mcolumn ellipsis separator"></span>
            </div>
        </div>
        </Link>
       </div>
    )
  }

  renderMessages() {
    return (
      <div className="inbox">
        {this.renderAllItemsList(this.state.messages)}
      </div>
    )
  }

  render() {
    return (
      <div className="Messages">
        {!this.state.isLoading ?
            this.renderMessages():
            <LoaderSpinner loadingText="Loading ..."/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activePlugin: state.activePlugin,
    installedPlugins: state.installedPlugins,
    account: state.account,
  }
}
const connectedMessages = connect(mapStateToProps)(Messages);

export default withAlert()(connectedMessages);