import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Support from "./containers/Support";
import AddNew from "./containers/AddNew";
import Messages from "./containers/Messages";
import Message from "./containers/Message";
import Browser from "./containers/Browser";
import Install from "./containers/Install";
import OnlineViewer from "./containers/OnlineViewer";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";


export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps}/>
    <Route path="/account" exact component={Login} props={childProps} />
    <Route path="/support" exact component={Support} props={childProps} />
    <AppliedRoute path="/addnew" exact component={AddNew} props={childProps} />
    <AppliedRoute path="/install" exact component={Install} props={childProps} />
    <AppliedRoute path="/browse" component={Browser} props={childProps}/>
    <AppliedRoute path="/view" component={OnlineViewer} props={childProps} />
    <AppliedRoute path="/messages" exact component={Messages} props={childProps} />
    <AppliedRoute path="/message" exact component={Message} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

