import React, { Component } from "react";
import * as PluginHelper from "../PluginHelper";
import "./Support.css";
import { connect } from 'react-redux'
import { withAlert } from 'react-alert'

class Support extends Component {
  render() {
    const mailtoLink = 'mailto:?subject=' + PluginHelper.RECOMMEND_EMAIL_SUBJECT + '&body=' + PluginHelper.RECOMMEND_EMAIL_CONTENT;
    return (
      <div className="Support">
        <div className="contact">
          <div className="title">Need help?</div>
          <div>Contact us at <a href='mailto:support@repfiles.net' target="_blank" rel="noopener noreferrer">support@repfiles.net</a> for help or to provide feedback.&nbsp;
          <a href='https://www.repfiles.net/Support.aspx' rel="noopener noreferrer" target="_blank">Visit our online support page</a> for how-to articles and videos.</div>
        </div>
        <hr/>
        <div className="recommend">
          <div className="title">Recommend a company to RepFiles</div>
          <div>Does your company work with a partner who is not yet delivering content through RepFiles? <a href={mailtoLink} target="_blank" rel="noopener noreferrer">Recommend them to join!</a></div>
        </div>
        <hr/>
        <div className="about">
          <div className="title"><span title={"RepFiles " + this.props.account.application.category + " " + this.props.version}>About RepFiles</span></div>
              <div>
               RepFiles provides a single location where sales reps, distributors and direct sales teams can access their manufacturers’ latest sales and marketing content.
               In addition to our web app, free mobile apps are available in the Apple App Store, Google Play and Microsoft Store.
               <br/><br/>Current editions built by and for the electrical and lighting industries include the NEMRA Edition, NAED Edition and Lighting Edition, as well as other company-branded apps.
               <br/><br/>Visit <a href='https://www.repfiles.net/' rel="noopener noreferrer" target="_blank">www.repfiles.net</a> for more information.
               </div>
        </div>
        <br/>
        <div className="release">Release {this.props.version}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    version: state.version
  }
}

const connectedSupport = connect(mapStateToProps)(Support);
export default withAlert()(connectedSupport);