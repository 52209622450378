import React, { Component } from "react";
import log from "../logger"
import * as FSHelper from "../FSHelper";
import * as PluginHelper from "../PluginHelper";
import { Image } from "react-bootstrap";
//import { Image, Form, OverlayTrigger} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import config from "../config";
import LoaderSpinner from "../components/LoaderSpinner";
import { withAlert } from 'react-alert'
import { formatAPIAlert } from '../components/AlertTemplate'
import "./OnlineViewer.css";

const SUPPORTED_MIME_TYPES = [ "application/pdf", "application/rtf", "application/vnd.ms-powerpoint", "application/vnd.ms-excel", "application/msword",
                               "image/", "video/", "audio/"];

const OFFICE_ONLINE_SERVICE = "https://view.officeapps.live.com/op/embed.aspx";
const OFFICE_ONLINE_SERVICE_PARAM = "src";
// https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be
const GOOGLEDOCS_ONLINE_SERVICE = "https://docs.google.com/viewer";
const GOOGLEDOCS_ONLINE_SERVICE_PARAM = "url";
const GOOGLEDOCS_ONLINE_SERVICE_EMBED_PARAMVALUE = "embedded";
const GOOGLEDOCS_ONLINE_MAXSIZE = 20 * 1024 * 1024;

const TAG = "OnlineViewer"

/*const renderTooltip = ({ placement,scheduleUpdate,arrowProps,outOfBoundaries, show: _show, ...props}: any) => (
  <div
    {...props}
    style={{
      backgroundColor: 'rgba(255, 255, 255, 1.0)',
      marginTop: '6px',
      padding: '3px 8px',
      color: 'black',
      borderWidth: '1px',
      borderRadius: 1,
      borderStyle: 'solid',
      borderColor: 'rgba(100, 100, 100, 1.0)',
      fontSize: '70%',
      ...props.style,
    }}
  >
    Alternate renderer
  </div>
);*/


class OnlineViewer extends Component {
  constructor(props) {
    super(props);

    log.info(TAG, "OnlineViewer location state", this.props.location.state);

    const file = this.props.location.state ? this.props.location.state.file : {};
    const supportedFile = this.isFileSupported(file);

    // File to display
    this.state = {
      isLoading: true,
      isFileLoading: supportedFile,
      isFileSupported: supportedFile,
      file: file,
      initialFile : file,
      index: 0,
      alternateRenderer: true,
    };
  }

  async componentDidMount() {
    try {
        if (this.state.file.ext === config.conf.MYFILES_PLUGIN.uid) {
            // Need to Share MyFiles items before viewing as it is SSE-C encrypted
            const sharedFiles = await FSHelper.downloadFilesShare(this.props.account.token, [this.state.file]);
            if (sharedFiles && sharedFiles.length === 1) {
                this.state.file.presignedUrl = sharedFiles[0].presignedUrl
                this.setState({file: this.state.file});
            }
            else {
                throw new Error("Cannot load item");
            }
        }
    } catch (e) {
      log.error(TAG, "componentDidMount", e);
      this.props.alert.error(formatAPIAlert(e));
    }
    log.info(TAG, "componentDidMount", this);
    this.setState({ isLoading: false });
    if (this.state.isFileLoading) {
        // Stop spinner after a few seconds and display refresh
        setTimeout(() => this.stopSpinner(), 5000);
    }
  }

   getNavigatorPlugin(name) {
       for (let key in navigator.plugins) {
           let plugin = navigator.plugins[key];
           // log.info(TAG, plugin);
           if ((plugin.name) && (plugin.name.indexOf(name) !== -1)) {
                return plugin;
           }
       }
   };

  isFileSupported(item) {
    let ret = false;
    if ((item) && (item.mimeType)) {
        for (let i=0; i<SUPPORTED_MIME_TYPES.length; i++) {
            if (item.mimeType.startsWith(SUPPORTED_MIME_TYPES[i])) {
                ret = true;
                break;
            }
        }
    }
    log.info(TAG, "isSupported", ret);
    return ret;
  }

  isSupported(item) {
    return this.state.isFileSupported;
  }

  stopSpinner() {
    if (this.state.isFileLoading) {
       this.setState({isFileLoading: false})
    }
  }

  renderBack(item) {
      const parentItem = FSHelper.getParent(item);
      if (parentItem && FSHelper.isDefined(parentItem.absolutePath))
      {
          return (
            <Link title="Back" to={{pathname: '/browse/', state: { initialFolder: parentItem}}}><Image  src="../images/back_arrow.png"/></Link>
          );
      }
  }

  updateiFrame(file) {
     if (this.isSupported(file)) {
         this.setState({isFileLoading: true, index: this.state.index + 1});
         setTimeout(() => this.stopSpinner(), 5000);
     }
  }

  renderRefresh(item) {
    if (this.isSupported(item)) {
        return (
            this.state.isFileLoading
            ? <Spinner animation="border" size="sm" />
            : <Link title="Refresh" to={{pathname: '/view/', state: { file: item }}} onClick={() => this.updateiFrame(item)}><Image  src="../images/refresh24.png"/></Link>
        )
    }
  }

  renderDownload(item) {
    if (this.isSupported(item)) {
        const filesToDownload = [item]
        return (
            <Link title="Download" to={{pathname: '/view/', state: { file: item }}} onClick={() => this.props.downloadFilesPrompt(filesToDownload)}><Image  src="../images/download32.png"/></Link>
        )
    }
  }

  renderAddToPlugin(item) {
    if (this.isSupported(item) && (this.props.activePlugin.uid !== config.conf.MYFILES_PLUGIN.uid)) {
        const filesToAdd = [item]
        return (
            <Link title="Add to MyFiles" to={{pathname: '/view/', state: { file: item }}} onClick={() => this.props.addFilesToPluginPrompt(filesToAdd)}><Image  src="../images/addtoplugin24.png"/></Link>
        )
    }
  }

  handleAlternatePluginChange = event => {
    log.info(TAG, "handleAlternatePluginChange", event.target.checked);
    this.refs.overlaySwitch.handleHide();
    this.setState({ alternateRenderer: event.target.checked});
  }

  /*renderSwitchToAlternatePlugin(item) {
    return (
      <OverlayTrigger
        rootClose={true}
        ref="overlaySwitch"
        placement="bottom"
        delay={{ show: 250, hide: 250 }}
        overlay={renderTooltip}
      >
        <Form>
          <Form.Check onChange={this.handleAlternatePluginChange}
            type="switch"
            id="custom-switch"
            label=""
            variant="success"
          />
        </Form>
        </OverlayTrigger>
    )
  }*/

  renderTitle(item) {
    //<div className="file-title-switch cell my-auto">
    //    {item.mimeType.startsWith("application/") ? this.renderSwitchToAlternatePlugin(item) : ""}
    //</div>
    log.log(TAG, "renderTitle", item);
    return (
        <div className="viewer-title" key="viewer_title">
            <div className="table my-auto">
              <div className="file-title-back cell my-auto">
                {this.renderBack(this.state.initialFile)}
              </div>
              <div className="file-title-download cell my-auto">
                {this.renderDownload(item)}
              </div>
              <div className="file-title-addtoplugin cell my-auto">
                {PluginHelper.isPluginInstalled(this.props.installedPlugins, config.conf.MYFILES_PLUGIN.uid) ? this.renderAddToPlugin(item) : ""}
              </div>

              <div className="file-title cell my-auto">{item.name}</div>
              <div className="file-title-details cell">
                 <div className="table my-auto">
                    <div className="file-title-reload cell my-auto">
                        {this.renderRefresh(item)}
                    </div>

                    <div className="cell my-auto">
                        {FSHelper.getBytes(item.size)}<br/>{FSHelper.formatDate(item.lastModified)}
                    </div>
                 </div>
              </div>

            </div>

        </div>
    );
  }

  itemLoaded() {
      this.setState({isFileLoading: false});
  }

  iFrameLoaded() {
      // Not called if not loaded successfully. Usually a HTTP 204 returned (no content)
      // https://stackoverflow.com/questions/40414039/google-docs-viewer-returning-204-responses-no-longer-working-alternatives
      // We cannot access iFrame content (even CSS) due to cross-domain browser security
      // document.querySelectorAll('iframe').forEach( item =>
      //    console.log(item.contentWindow)
      // )
      this.itemLoaded();
  }

  iFrameLoadError() {
     console.log(TAG, this.iFrameLoadError.name, "Cannot load content");
  }

  renderContent(item) {
    if (this.isSupported(item)) {
        if (item.mimeType.startsWith("application/"))
        {
            if ((item.size < GOOGLEDOCS_ONLINE_MAXSIZE) && (this.state.alternateRenderer === false))
            {
                const url = GOOGLEDOCS_ONLINE_SERVICE + "?" + GOOGLEDOCS_ONLINE_SERVICE_PARAM + "=" + encodeURIComponent(item.presignedUrl)  + "&" + GOOGLEDOCS_ONLINE_SERVICE_EMBED_PARAMVALUE + "=true";
                log.info(TAG, this.renderContent.name, url);
                return (
                   <iframe className="viewer-iframe" key={this.state.index} src={url} title="file_content" frameBorder='0'
                    onLoad={() => this.iFrameLoaded()} onError={() => this.iFrameLoadError()}></iframe>
                )
            }
            else {
                if (!item.mimeType.startsWith("application/pdf")) {
                    const url = OFFICE_ONLINE_SERVICE + "?" + OFFICE_ONLINE_SERVICE_PARAM + "=" + encodeURIComponent(item.presignedUrl);
                    log.info(TAG, this.renderContent.name, url);
                    return (
                       <iframe className="viewer-iframe" src={url} title="file_content" frameBorder='0'
                        onLoad={() => this.iFrameLoaded()} onError={() => this.iFrameLoadError()}></iframe>
                    )
                }
                else {
                    if (item.mimeType.startsWith("application/pdf"))  {
                        let pdfPlugin = this.getNavigatorPlugin('Adobe Acrobat') || this.getNavigatorPlugin('PDF');
                        if (!!pdfPlugin) {
                            log.info(TAG, "PDF plugin detected", pdfPlugin)
                            if ((item.presignedUrl.includes(".sharepoint.com/")) && (item.size < GOOGLEDOCS_ONLINE_MAXSIZE)) {
                                // Force Google Viewer to workaround file download (content-disposition header cannot be modified)
                                const url = GOOGLEDOCS_ONLINE_SERVICE + "?" + GOOGLEDOCS_ONLINE_SERVICE_PARAM + "=" + encodeURIComponent(item.presignedUrl)  + "&" + GOOGLEDOCS_ONLINE_SERVICE_EMBED_PARAMVALUE + "=true";
                                log.info(TAG, this.renderContent.name, url);
                                return (
                                   <iframe className="viewer-iframe" key={this.state.index} src={url} title="file_content" frameBorder='0'
                                    onLoad={() => this.iFrameLoaded()} onError={() => this.iFrameLoadError()}></iframe>
                                )
                            }
                            else {
                                // Try to embed PDF in browser (if supported)
                                return (
                                   <iframe className="viewer-iframe" key={this.state.index} src={item.presignedUrl + "#toolbar=0"} title="file_content" frameBorder='0'
                                    onLoad={() => this.iFrameLoaded()} onError={() => this.iFrameLoadError()}>This browser does not support PDFs. Please download the PDF to view it.</iframe>
                                )
                            }
                        }
                        else {
                            if (item.size < GOOGLEDOCS_ONLINE_MAXSIZE)
                            {
                                const url = GOOGLEDOCS_ONLINE_SERVICE + "?" + GOOGLEDOCS_ONLINE_SERVICE_PARAM + "=" + encodeURIComponent(item.presignedUrl)  + "&" + GOOGLEDOCS_ONLINE_SERVICE_EMBED_PARAMVALUE + "=true";
                                log.info(TAG, this.renderContent.name, url);
                                return (
                                   <iframe className="viewer-iframe" key={this.state.index} src={url} title="file_content" frameBorder='0'
                                    onLoad={() => this.iFrameLoaded()} onError={() => this.iFrameLoadError()}></iframe>
                                )
                            }
                            else {
                                return (
                                   <p className="viewer-error">Document too large to be viewed online, please download it.</p>
                                )
                            }
                        }
                    }
                    else {
                        return (
                           <p className="viewer-error">Document too large to be viewed online, please download it.</p>
                        )
                    }
                }
            }
        }
        else if (item.mimeType.startsWith("image/")) {
            return (
                <div className="icon-top-container">
                  <div className="icon-container">
                    <Image className="center-image" src={item.presignedUrl} onLoad={() => this.itemLoaded()}/>
                  </div>
                </div>
            )
        }
        else if ((item.mimeType.startsWith("video/")) || (item.mimeType.startsWith("audio/")) ){
            return (
                <video className="viewer-video" id="movie" playsInline autoPlay="autoplay" muted controls src={item.presignedUrl}
                 preload="true" onCanPlay={() => this.itemLoaded()}></video>
            )
        }
        else {
            return (
                <p className="viewer-error">Cannot render document, please download it.</p>
            )
        }
    }
    else {
        return (
            <p className="viewer-error">Cannot render document, please download it.</p>
        )
    }

  }

  renderFile(item) {
    return (
        <div className="file">
            {this.renderTitle(item)}
            <div className="viewer-content" key="viewer_content">
                {this.renderContent(item)}
            </div>
        </div>
    )
  }

  render() {
    return (
      <div className="OnlineViewer">
        {!this.state.isLoading
            ? this.renderFile(this.state.file)
            : <LoaderSpinner loadingText="Loading ..." key="loadingTitle"/>
        }
      </div>
    )
  }
}

export default withAlert()(OnlineViewer);